import styles from './GravitateCard.module.css';
import featuredIcon from '../../assets/icons/featured-icon.svg';

function GravitateCard() {
  return (
    <div
      className={styles.GravitateCard}
      data-cy='gravitate-card'
    >
      <img
        src={featuredIcon}
        alt='Featured Icon'
        className={styles.featuredIcon}
      />
      <h4 className={styles.header}>“I gravitate...”</h4>
      <p className={styles.body}>
        The phrase emerged with such clarity as we researched the affects of assessments like these. For us, it unearths subliminal characteristics, underlying biases and ultimate desires of who we think we are, compared to who we are working to become. 
      </p>
    </div>
  );
}

export default GravitateCard;