import ResultsAreIn from '../../subsections/ResultsAreIn';
import GravitationalIdeology from '../../subsections/GravitationalIdeology';
import ResultsOverview from '../../subsections/ResultsOverview';
import InfoBlock from '../../subsections/InfoBlock';
import styles from './Overview.module.css';

const reference = styles.overview + ' screen';

export default function Overview() {
  return (
    <section
      data-cy='overview'
      className={reference}
      id='overview'
    >
      <ResultsAreIn />
      <GravitationalIdeology />
      <ResultsOverview />
      <InfoBlock />
      <hr className={styles.hr} />
    </section>
  );
}
