import styles from './QuoteImage.module.css';

export default function QuoteImage() {
  return (
    <div className={ styles.container }>
      <div className={ styles.quote }>
        <p className={ styles.text }>
          “Talent wins games, but teamwork and intelligence win championships.”
        </p>
        <p className={ styles.author }>
          Michael Jordan
        </p>
      </div>
    </div>
  );
}