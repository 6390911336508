import { useContext } from 'react';
import { ResultsContext } from '../../context/ResultsContext';
import PropTypes from 'prop-types';
import styles from './CharacteristicCardContainer.module.css';
import globalStyles from '../../styles/global.module.css';
import './CharacteristicCardContainer.css';
import ResultsError from '../ResultsError';
import CharacteristicCard from './CharacteristicCard';

export default function CharacteristicCardContainer({ windowWidth }) {
  const results = useContext(ResultsContext);

  if ( results.loading ) {
    return null;
  }
  if ( results.error ) {
    return <ResultsError />;
  }
  if ( windowWidth < 768 ) {
    return <Carousel results={ results } />;
  }
  return <NonMobileLayout results={ results } />;
}

CharacteristicCardContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

function Carousel({ results }) {
  const containerClass = globalStyles['container-width'] + ' ' + styles.CharacteristicCardContainer;
  return (
    <div 
      className={containerClass}
      data-cy='characteristic-cards-carousel'
      id='characteristic-cards-carousel'
    >
      <Swiper
        spaceBetween={6}
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        <SwiperSlide className={styles.SwiperSlide}>
          <CharacteristicCard
            characteristic='Appetite for Risk'
            score={ results.highRiskScore }
          />
        </SwiperSlide>
        <SwiperSlide>
          <CharacteristicCard
            characteristic='Desire for Reward'
            score={ results.highRewardScore }
          />
        </SwiperSlide>
        <SwiperSlide>
          <CharacteristicCard
            characteristic='Aptitude for Responsibility'
            score={ results.highResponsibilityScore }
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

Carousel.propTypes = {
  results: PropTypes.object.isRequired,
};

function NonMobileLayout({ results }) {
  const containerClass = globalStyles['container-width'] + ' ' + styles.CharacteristicCardContainer;

  return (
    <div className={containerClass}>
      <CharacteristicCard
        characteristic='Appetite for Risk'
        score={ results.highRiskScore }
      />
      <CharacteristicCard
        characteristic='Desire for Reward'
        score={ results.highRewardScore }
      />
      <CharacteristicCard
        characteristic='Aptitude for Responsibility'
        score={ results.highResponsibilityScore }
      />
    </div>
  );
}

NonMobileLayout.propTypes = {
  results: PropTypes.object.isRequired,
};