import styles from './SkillsTalentsRank.module.css';
import illustrationImage from '../../assets/illustrations/skills-talents-rank.svg';

export default function SkillsTalentsRank() {
  return (
    <section
      className={styles.SkillsTalentsRank}
      data-cy='skills-talents-rank'
    >
      <img
        src={illustrationImage}
        alt='Skills, Talents, and Rank illustration'
        className={styles.illustration}
      />
      <div className={styles.textContent}>
        <h2 className={styles.header}>Skills, Talents & Rank</h2>
        <p className={styles.body}>
          <span>Whether they are the learned habits we have formed by operating in certain domains, or “natural instincts” we have been blessed with, we like to distinguish these traits as the differences between </span>
          <span className={styles.bold}>talents</span>
          <span> and </span>
          <span className={styles.bold}>skills</span>
          <span>. These differences are also tempered by our experience which we define as </span>
          <span className={styles.bold}>rank</span>
          <span>.</span>
        </p>
      </div>
    </section>
  );
}