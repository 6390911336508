import styles from './InnovationMindsetTapping.module.css';
import globalStyles from '../../styles/global.module.css';
import icon from './img/tapping-into-your-potential-icon.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function InnovationMIndsetTapping () {
  const containerStyles = globalStyles['container-width'] + ' ' + styles.InnovationMindsetTapping;
  return(
    <div
      className={containerStyles}
    >
      <ContentContainer />
    </div>
  );
}

function ContentContainer () {

  return (<>
    <div
      className={styles.contentContainer}>
      <ContentLayout />
      <div className={styles.iconContainer}>
        <img
          className={styles.icon}
          src={icon}
          alt='Tapping into your potential icon'
        />
      </div>
    </div>
  </>);
}

function ContentLayout() {
  const width = useWindowWidth();

  if( width >= 768 && width <= 991 ) {
    return (<>
      <h3>Tapping into your innovative potential</h3>
      <div className={styles.content}>
        <p>The disconnect between language and understanding leads to dissonance within collaboration, drummed up from warped perspectives.</p>
        <p>So, our team has decided to lead a new conversation focused on developing the innovator within, free from the shackles of societal expectations and fleeting trends.</p>
        <p>Recognizing the intricacies of your mind will unveil how your instinctual impulses and acquired habits determine your optimal role in an innovative system.</p>
      </div>
    </>);
  }
  return (
    <div
      className={styles.content}>
      <h3>Tapping into your innovative potential</h3>
      <p>The disconnect between language and understanding leads to dissonance within collaboration, drummed up from warped perspectives.</p>
      <p>So, our team has decided to lead a new conversation focused on developing the innovator within, free from the shackles of societal expectations and fleeting trends.</p>
      <p>Recognizing the intricacies of your mind will unveil how your instinctual impulses and acquired habits determine your optimal role in an innovative system.</p>
    </div>
  );
}
