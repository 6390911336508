import PropTypes from 'prop-types';
import styles from './CCC_Card.module.css';
import communicationIcon from '../../assets/icons/communication.svg';
import collaborationIcon from '../../assets/icons/collaboration.svg';
import compromiseIcon from '../../assets/icons/compromise.svg';

const cardDetailsObject = {
  'Communication': {
    icon: communicationIcon,
    description: 'There are many factors involved with Communication, but in team dynamics, we need to first recognize core dispositions. There are three main styles - Passive, Exploratory and Dominant - that our assessment explores.',
  },
  'Collaboration': {
    icon: collaborationIcon,
    description: 'When individuals come together to build, create and innovate, they also bring with them preferences of Collaboration. There are two main approaches from a high-level view: Task-oriented and People-oriented approaches.',
  },
  'Compromise': {
    icon: compromiseIcon,
    description: 'While some may view Compromise as a losing game, others perceive the potential. It’s crucial to remember that you’re all on the same team, so your Willingness or Unwilling to Compromise can hold y’all back, or accelerate growth.',
  },
};

export default function CCC_Card({ cardType }) {
  const { icon, description } = cardDetailsObject[cardType];

  return (
    <div 
      className={styles.CCC_Card}
      data-cy='ccc-card'
    >
      <img
        src={icon}
        alt={cardType}
        className={styles.icon}
      />
      <h2 className={styles.header}>{ cardType }</h2>
      <p className={styles.description}>
        { description }
      </p>
    </div>
  );
}

CCC_Card.propTypes = {
  cardType: PropTypes.string.isRequired,
};