import styles from './CCC_Styles.module.css';
import CCC_Style_Cards from '../../components/CCC_Style_Cards';

export default function CCC_Styles() {
  return (
    <div className={styles.CCC_Styles}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>Every person speaks, moves and negotiates differently. How about you?</h2>
        <p className={styles.subheader}>Rather than demanding change from others to fit our limited lens of understanding, we should make strides in doing the opposite. Approach projects with an open mind, conduct proactive discussions, and ask questions that propel the team forward.</p>
        <div className={styles.reminderChip}>
          <p>Remember, we’re all on the same team, so why wouldn’t we help each other to win?</p>
        </div>
      </div>
      <CCC_Style_Cards />
    </div>
  );
}