import PropTypes from 'prop-types';
import globalStyles from '../../styles/global.module.css';
import styles from './GravitationalIdeologyCard.module.css';
import dotIcon from '../../assets/icons/dot.svg';
import curvedArrow from '../../assets/icons/curved-arrow.svg';

export default function GravitationalIdeologyCard({ windowWidth }) {
  return (<>
    <div
      data-cy='main-card' 
      className={styles.GravitationalIdeologyCard}
    >
      <p className={styles.preheader}>Gravitational Ideology</p>
      <h3 className={styles.header}>Just like the earth, people are meant to evolve.</h3>
      <p className={styles.body}>
        <span>But as life takes hold of the steering wheel and turns on cruise control, we often forget to take moments to pause and reflect on our current stage in life. The ACT Assessment is not a personality test. </span>
        <span className={styles.bold}>We are not here to tell you who you are. </span>
        <span>Not only because we have no right to do so, but because you are more than the thoughts you imagine and the emotions you feel. All we hope for is to provide color to your gravitational pull.</span>
      </p>
      { windowWidth >= 768 && <ThoughtLabels mobile={false} /> }
      { windowWidth >= 1440 && <CurvedArrow /> }
    </div>
    { windowWidth < 768 && <ThoughtLabels mobile={true} /> }
  </>);
}

GravitationalIdeologyCard.propTypes = {
  windowWidth: PropTypes.number.isRequired
};

function ThoughtLabels({ mobile }) {
  const labelTexts = [
    'What do you act on?',
    'What captures your attention and sparks your spirit?',
    'What draws you in?'
  ];
  if (!mobile) { // swap order of labels for >=768px
    const temp = labelTexts[1];
    labelTexts[1] = labelTexts[2];
    labelTexts[2] = temp;
  }

  return (
    <div 
      className={styles.ThoughtLabels}
      data-cy='thought-labels'
    >
      { labelTexts.map((text) => (
        <ThoughtLabel
          key={text}
          text={text} 
        />
      )) }
    </div>
  );
}

ThoughtLabels.propTypes = {
  mobile: PropTypes.bool,
};

const labelSyles = globalStyles['text-sm-medium'] + ' ' + styles.ThoughtLabel;

function ThoughtLabel({ text }) {
  return (
    <div className={labelSyles}>
      <img 
        src={dotIcon} 
        alt='dot' 
      />
      <p>{ text } </p>
    </div>
  );
}

ThoughtLabel.propTypes = {
  text: PropTypes.string,
};

function CurvedArrow() {
  return (
    <img 
      src={curvedArrow} 
      alt='curved arrow' 
      className={styles.CurvedArrow}
    />
  );
}