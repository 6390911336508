import styles from './InnovationMindset.module.css';

import Overview from '../../components/InnovationMindsetOverview';
import Ability from '../../components/InnovationMindsetAbility';
import Tapping from '../../components/InnovationMindsetTapping';
import RiskRewardResponsibilityCards from '../../subsections/RiskRewardResponsibilityCards';
import EntreVsIntrapeneurs from '../../subsections/EntreVsIntrapeneurs';
import EntreIntraPairCards from '../../subsections/EntreIntraPairCards';
import CharacteristicsResults from '../../subsections/CharacteristicsResults';

const reference = styles.innovationMindset + ' screen';

export default function InnovationMindset() {
  return (
    <section
      className={reference}
      id='innovationMindset'>
      <Overview />
      <hr />
      <Ability />
      <hr className={styles.hidden320} />
      <Tapping />
      <hr className={styles.hidden320} />
      <RiskRewardResponsibilityCards />
      <hr className={styles.hidden320} />
      <EntreVsIntrapeneurs />
      <EntreIntraPairCards />
      <hr className={styles.hidden320} />
      <CharacteristicsResults />
    </section>
  );
}
