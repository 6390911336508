import globalStyles from '../../styles/global.module.css';
import styles from './ArchetypeDiscovery.module.css';
import archetypeDiscoveryIcon from '../../assets/icons/archetype-discovery.svg';
import MagnifyingGlass from '../../components/MagnifyingGlass';
import ArchetypeCards from '../../subsections/ArchetypeCards';
import WaysToGetBetterCard from '../../subsections/WaysToGetBetterCard';

export default function ArchetypeDiscovery() {
  const containerStyles = globalStyles['container-width'] + ' ' + styles.headerContainer;
  return <section
    className='screen'
    id='archetypeDiscovery'
  >
    <div
      className={containerStyles}
      data-cy='archetype-discovery-header-container'
    >
      <img
        src={archetypeDiscoveryIcon}
        alt="Archetype Discovery"
        className={styles.archetypeDiscoveryIcon}
        data-cy='archetype-discovery-icon'
      />
      <div
        data-cy='archetype-discovery-header'
        className={styles.headerTextContainer}
      >
        <h2 className={styles.header}>Archetype Discovery</h2>
        <p className={styles.description}>
          Ultimately, your Dominant and Subdominant Archetypes provide you framing for your gravitational pull to understand your stage in life, areas you want to grow in, and people you should work with.
        </p>
      </div>
      <MagnifyingGlass />
    </div>
    <ArchetypeCards />
    <WaysToGetBetterCard />
  </section>;
}
