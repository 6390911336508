import styles from './ACTModelCards.module.css';
import './ACTModelCards.css';
import ACTModelCard from '../ACTModelCard';
import linePattern from '../../assets/line-patterns/line-pattern-1.svg';
import polygon1 from '../../assets/icons/polygon-green-1.svg';
import useWindowWidth from '../../hooks/useWindowWidth';
import ACTExamplesArray from '../../utils/act-model-examples';

export default function ACTModelCards() {
  const windowWidth = useWindowWidth();

  return (
    <section>
      { windowWidth < 768 ?
        <Carousel />
        :
        <div className={styles.cardContainer}>
          {
            ACTExamplesArray.map( exampleTriad => {
              return (
                <ACTModelCard
                  key={exampleTriad.triadName}
                  triadInfo={exampleTriad}
                />
              );
            })
          }
          <BackgroundPatterns />
        </div>
      }
    </section>
  );
}

// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

function Carousel() {
  return (
    <div 
      className={styles.ACTModelCards}
      data-cy='act-example-cards-carousel'
      id='act-example-cards-carousel'
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {
          ACTExamplesArray.map( exampleTriad => {
            return (
              <SwiperSlide key={exampleTriad.triadName}>
                <ACTModelCard triadInfo={exampleTriad}/>
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </div>
  );
}

function BackgroundPatterns() {
  return (<>
    <img
      className={`${styles.linePattern} ${styles.linePattern1}`}
      src={linePattern}
      alt="background line pattern"
    />
    <img
      className={`${styles.linePattern} ${styles.linePattern2}`}
      src={linePattern}
      alt="background line pattern"
    />
    <img
      className={`${styles.polygon} ${styles.polygon1}`}
      src={polygon1}
      alt="background polygon"
    />
    <img
      className={`${styles.polygon} ${styles.polygon2}`}
      src={polygon1}
      alt="background polygon"
    />
  </>);
}