import styles from './TeamCohesion.module.css';
import globalStyles from '../../styles/global.module.css';
import teamCohesionIcon from '../../assets/icons/team-cohesion-quotient.svg';
import headerIllustration from '../../assets/illustrations/team-cohesion-header.svg';
import QuoteImage from '../../subsections/QuoteImage';
import SuccessRates from '../../subsections/SuccessRates';
import WellBalancedRecipe from '../../subsections/WellBalancedRecipe';
import SuperstarPairing from '../../subsections/SuperstarPairing';
import CCC_Styles from '../../subsections/CCC_Styles';
import ACTModelExamples from '../../subsections/ACTModelExamples';

export default function TeamCohesion() {
  const containerClass = `screen ${globalStyles['container-width']} ${styles.TeamCohesion}`;
  return (
    <section 
      className={ containerClass }
      id='teamCohesion'
    >
      {/* Header */}
      <div className={ styles.headerContainer }>
        <div className={ styles.iconContainer }>
          <img
            src={ teamCohesionIcon }
            alt='Team Cohesion Quotient'
            className={ styles.icon }
          />
        </div>
        <div>
          <h2 className={ styles.header }>Team Cohesion Quotient</h2>
          <p className={ styles.subheader }>One of the top reasons why startups fail is team disharmony. Our ACT model is here to fix that.</p>
        </div>
        <img
          src={ headerIllustration }
          alt='Team Cohesion Quotient'
          className={ styles.illustration }
        />
      </div>
      {/* Quote */}
      <QuoteImage />
      {/* Success Rates */}
      <SuccessRates />
      {/* Well Balanced Recipe */}
      <WellBalancedRecipe />
      {/* Superstar Pairing */}
      <SuperstarPairing />
      {/* CCC Styles */}
      <CCC_Styles />
      {/* ACT Model Examples */}
      <ACTModelExamples />
    </section>
  );
}