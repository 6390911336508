import PropTypes from 'prop-types';
import styles from './CharacteristicCard.module.css';
import riskIcon from '../../../assets/icons/risk.svg';
import rewardIcon from '../../../assets/icons/reward.svg';
import responsibilityIcon from '../../../assets/icons/responsibility.svg';

const characteristics = {
  'Appetite for Risk': {
    icon: riskIcon,
    description: 'Your appetite for Risk depends on where your focus lies - are you wary about what could be lost or excited about what could be gained?',
    highText: 'You tend to take risks, but only if the reward is worth it--to you. Taking calculated risks can be a mismanaged skill, but you do it well. Just make sure you don’t mistake complacency for comfort.',
    lowText: 'You prefer to stay in zones of familiarity. This may be because you are comfortable in where you are and prefer to stay in this present state, which isn’t necessarily a bad thing. However, be wary of stagnation.',
  },
  'Desire for Reward': {
    icon: rewardIcon,
    description: 'People have different perceptions of gratification, such as intrinsic merit vs. extrinsic recognition, and the impact of the Reward is often contingent upon the value of the work.',
    highText: 'You’re fueled by displays of gratitude and enjoy recognition for your work. Although it’s important to see the fruits of your labor, be mindful of what kinds of rewards are satiating your desires.',
    lowText: 'You prefer personal expressions of appreciation rather than public displays of gratitude. You believe that the work you put in will be acknowledged and appreciated in a fair manner.'
  },
  'Aptitude for Responsibility': {
    icon: responsibilityIcon,
    description: 'Responsibility means more than just checking things off of your to-do list. When push comes to shove, can you follow through and follow up?',
    highText: 'You’ve been through the trenches and understand the benefits of being accountable. You’ve stopped taking things personally and know how to gracefully move with strength and humility.',
    lowText: 'You prefer to move alone, it’s easier to stay on top of your own tasks than being accountable for others. It’s okay to know your cap, but attempting to take on more can accelerate your growth.'
  },
};

export default function CharacteristicCard({ characteristic, score }) {
  const { icon, description, highText, lowText } = characteristics[characteristic];
  
  const isHigh = score > 3;
  const resultHeader = isHigh ? 'High' : 'Low';
  const resultText = isHigh ? highText : lowText;

  return (
    <div className={ styles.CharacteristicCard }>
      <div className={ `${styles.innerCard} ${styles.topCard}` }>
        <img
          src={ icon }
          alt={ characteristic }
          className={ styles.icon }
        />
        <h3>{ characteristic }</h3>
        <p>{ description }</p>
      </div>
      <div className={ `${styles.innerCard} ${styles.bottomCard}` }>
        <h3>{ resultHeader }</h3>
        <p>{ resultText }</p>
      </div>
    </div>
  );
}

CharacteristicCard.propTypes = {
  characteristic: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};