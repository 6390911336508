import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './WaysToGetBetterCard.module.css';
import globalStyles from '../../styles/global.module.css';
import tipIcon from '../../assets/icons/featured-icon_purple.svg';
import { ResultsContext } from '../../context/ResultsContext';

const tipsByType = {
  'Architect': [
    {
      title: 'Organize your KPIs.',
      text: 'Start with larger goals, then break them down into smaller tasks with due dates.',
    },
    {
      title: 'Create reading goals.',
      text: 'Choose a book on business principles or personal development and commit to reading a chapter a day. Start small, then expand.',
    },
    {
      title: 'Follow through with your vision.',
      text: 'It’s easy to get caught up in the dialogue, but you need to execute.',
    },
    {
      title: 'Ways to leverage',
      tips: [
        'Tackle your greatest challenge of the day first.',
        'Use the 5-minute rule to start any daunting task.',
        'Make room for ‘quiet’ breaks within your day where you separate yourself from your work to let your mind rest.'
      ],
    }
  ],
  'Creative': [
    {
      title: 'Expand your arsenal.',
      text: 'Find a new skill or technique in a different field to enhance your current skillset.',
    },
    {
      title: 'Revisit old work.',
      text: 'Looking back on past work can spark new ideas, show how much growth you’ve seen, and motivate you to keep on improving.',
    },
    {
      title: 'Create efficient processes.',
      text: 'Developing new ways to optimize performance will keep you on track and at your best.',
    },
    {
      title: 'Ways to leverage',
      tips: [
        'Remember that everything doesn’t need outside feedback. Lean into your gut instincts.',
        'Take moments to give yourself grace and to celebrate your wins.',
        'Jot it down and let it flow. Keep a small notebook at hand to make sure you keep note of new ideas. Inspiration can come from anywhere, but it’s up to you to capture it.'
      ],
    }
  ],
  'Techie': [
    {
      title: 'Let your mind breathe.',
      text: 'To avoid getting stuck on a task for too long, leave it alone for a day (or longer), so you can come back to it later with fresh eyes..',
    },
    {
      title: 'Stay diligent.',
      text: 'It can be tempting to cut corners on a time-consuming project, but the shortcut you take today may turn into a new barrier for the future.',
    },
    {
      title: 'Be intentional for growth.',
      text: 'Commit to learning a new skill each month or improving a technique every day.',
    },
    {
      title: 'Ways to leverage',
      tips: [
        'Create focus hours in your day to free your mind from any distractions.',
        'Start writing daily notes on paper. It will engage your neurological system to retain the information better, while also keeping yourself accountable.',
        'Dip your hand into a new interest or project that contrasts your daily work. This can help provide dynamic perspectives as you flex different muscles.'
      ],
    }
  ]
};

export default function WaysToGetBetterCard() {
  const results = useContext(ResultsContext);

  if ( results.loading ) {
    return null;
  }
  
  const tips = tipsByType[results.subDominantType];
  const containerStyles = globalStyles['container-width'] + ' ' + styles.WaysToGetBetterCard;
  return (
    <div 
      className={containerStyles}
      data-cy="ways-to-get-better-card"
    >
      <h2 className={styles.header}>Ways to get 2% better</h2>
      <div>
        { tips.map( (tip, index) => {
          return (<React.Fragment key={tip.title}>
            <TipContainer
              {...tip} 
            />
            { index !== tips.length - 1 && <hr className={styles.hr} /> }
          </React.Fragment>);
        })}
      </div>
    </div>
  );
}

function TipContainer({ title, text, tips }) {
  return (
    <div className={styles.Tip}>
      <img
        src={tipIcon}
        alt="Tip icon"
        className={styles.tipIcon}
      />
      <div>
        <h3 className={styles.title}>{title}</h3>
        {
          title === 'Ways to leverage' ?
            <TipList textArray={tips} /> :
            <TipText text={text} />
        }
      </div>
    </div>
  );
}

TipContainer.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  tips: PropTypes.arrayOf(PropTypes.string)
};

function TipText({ text }) {
  return (
    <p className={styles.text}>{text}</p>
  );
}

TipText.propTypes = {
  text: PropTypes.string.isRequired,
};

function TipList({ textArray }) {
  return (
    <ul className={styles.list}>
      { textArray.map( (text, index) => {
        return (
          <li key={index}>
            <TipText text={text} />
          </li>
        );
      })}
    </ul>
  );
}

TipList.propTypes = {
  textArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};