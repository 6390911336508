import PropTypes from 'prop-types';
import styles from '../SuccessCircles.module.css';
import ProgressCircle from '../ProgressCircle';

export default function CirclesCard2({ windowWidth }) {
  const componentOrder = windowWidth < 768 ? [Header, Text, Circles] : [Header, Circles, Text];
  return (
    <div className={ `${styles.circlesCard} ${styles.circlesCard2}` }>
      { componentOrder.map(Component => <Component key={`cc-${Component.name}`} />) }         
    </div>
  );
}

CirclesCard2.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

function Header() {
  return <h6>Implementing the ACT Model</h6>;
}

function Text() {
  return (
    <div className={ styles.textContainer }>
      <p>What started from a 10% success rate for prototyping, it quickly reached an all-time high when we used our ACT model and methodology.</p>
      <p>Knowing that every team needs an Architect, Creative, and Techie, the ACT Model serves as the strong foundational backbone of team formation and product development.</p>
    </div>
  );
}

function Circles() {
  return (
    <div className={ styles.circleInnerContainer }>
      <div className={ styles.circle }>
        <ProgressCircle 
          score={16}
          year={'2017'}
        />
      </div>
      <div className={ styles.circle }>
        <ProgressCircle 
          score={19}
          year={'2018'}
        />
      </div>
      <div className={ styles.circle }>
        <ProgressCircle 
          score={20}
          year={'2019'}
        />
      </div>
    </div>
  );
}