import PropTypes from 'prop-types';
import styles from './CharacteristicsResults.module.css';
import CharacteristicCardContainer from '../../components/CharacteristicCardContainer';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function CharacteristicsResults() {
  const windowWidth = useWindowWidth();

  return (
    <section
      className={styles.CharacteristicsResults}
      id='characteristicsResults'
    >
      <Header windowWidth={windowWidth} />
      <SubHeader windowWidth={windowWidth} />
      <CharacteristicCardContainer windowWidth={windowWidth} />
    </section>
  );
}

function Header({ windowWidth }) {
  const headerText = windowWidth < 768 ? 
    'Do you gravitate towards an Entrepreneurial or Intrapreneurial Mindset?' 
    : 
    'How your mindset influences your gravitational pull as an entrepreneur and intrapreneur.';

  return <h2>{ headerText }</h2>;
}

Header.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

function SubHeader({ windowWidth }) {  
  if (windowWidth < 768) {
    return null;
  }

  return <p>We scoured the earth for characteristics of an Entrepreneur and Intrapreneur. We found 63 characteristics but we believe only 3 (really) matter.</p>;
}

SubHeader.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};