import styles from './SuccessCircles.module.css';
import useWindowWidth from '../../hooks/useWindowWidth';
import CirclesCard1 from './CirclesCard1';
import CirclesCard2 from './CirclesCard2';

export default function SuccessCirclesContainer() {
  const windowWidth = useWindowWidth();

  return (
    <div className={ styles.circleParentContainer }>
      <CirclesCard1 windowWidth={windowWidth} />
      <CirclesCard2 windowWidth={windowWidth} />
    </div>
  );
}