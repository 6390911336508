import { useContext } from 'react';
import { ResultsContext } from '../../context/ResultsContext';
import PropTypes from 'prop-types';
import globalStyles from '../../styles/global.module.css';
import styles from './ArchetypeCards.module.css';
import './ArchetypeCards.css';
import ArchetypeCard from '../../components/ArchetypeCard';
import ResultsError from '../../components/ResultsError';
import useWindowWidth from '../../hooks/useWindowWidth';
// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

export default function ArchetypeCards() {
  const windowWidth = useWindowWidth();
  const results = useContext(ResultsContext);

  if ( results.loading ) {
    return null;
  }
  if ( results.error ) {
    return <ResultsError />;
  }

  const {
    dominantType,
    dominantScore,
    subDominantType,
    subDominantScore,
  } = results;
  
  if ( windowWidth < 690 ) {
    return (
      <div>
        { renderOutlierPill(dominantScore, subDominantScore) }
        <Carousel
          dominantType={dominantType}
          dominantScore={dominantScore}
          subDominantType={subDominantType}
          subDominantScore={subDominantScore}
        />
      </div>
    );
  }
  return (
    <section className={globalStyles['container-width']}>
      { renderOutlierPill(dominantScore, subDominantScore) }
      <div className={styles.ArchetypeCards}>
        <ArchetypeCard
          archetype={dominantType}
          dominance='Dominant'
          score={dominantScore}
        />
        <ArchetypeCard
          archetype={subDominantType}
          dominance='Subdominant'
          score={subDominantScore}
        />
      </div>
    </section>
  );
}

function Carousel({ dominantType, dominantScore, subDominantType, subDominantScore }) {
  return (
    <div 
      className={styles.ArchetypeCards}
      data-cy='archetype-cards-carousel'
      id='archetype-cards-carousel'
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        <SwiperSlide className={styles.SwiperSlide}>
          <ArchetypeCard
            archetype={dominantType}
            dominance='Dominant'
            score={dominantScore}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ArchetypeCard
            archetype={subDominantType}
            dominance='Subdominant'
            score={subDominantScore}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

Carousel.propTypes = {
  dominantType: PropTypes.string.isRequired,
  dominantScore: PropTypes.number.isRequired,
  subDominantType: PropTypes.string.isRequired,
  subDominantScore: PropTypes.number.isRequired,
};

function renderOutlierPill(dominantScore, subDominantScore) {
  if ( dominantScore !== subDominantScore ) {
    return null;
  }

  const isTripleTie = ( dominantScore === 7 && subDominantScore === 7 ); // Per client: The only way to get a triple tie is if both dominant and subdominant are 7, all other ties are doubles.
  const tieText = isTripleTie ? 'triple' : 'double';
  return (
    <div className={styles.outlierPill}>
      <p>
        Whoa, you rolled a {tieText}! Reach out to our team for deeper insights.
      </p>
    </div>
  );
}