import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResultsContext } from '../../context/ResultsContext';
import globalStyles from '../../styles/global.module.css';
import styles from './EntreVsIntrapeneurs.module.css';
import './EntreVsIntrapeneurs.css';
import BlueCard from '../../components/BlueCard';
import useWindowWidth from '../../hooks/useWindowWidth';
// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

export default function EntreVsIntrapeneurs() {
  const windowWidth = useWindowWidth();

  const containerStyles = styles.containerStyles + ' ' + globalStyles['container-width'];
  return (
    <section className={containerStyles}>
      <Header windowWidth={windowWidth} />
      {windowWidth < 768 ?
        <Carousel />
        :
        <div 
          className="flex space-x-1 rounded-2xl bg-slate-100 p-4" 
          aria-orientation="horizontal"
        >
          <BlueCard 
            type='entre' 
            highlighted
          />
          <BlueCard 
            type='intra' 
          />
        </div>
      }
    </section>
  );
}

function Carousel() {
  const {
    intrapreneurScore,
    entrepreneurScore,
  } = useContext(ResultsContext);

  // We'll check if the user has a gravity towards Intrapreneurship
  // so that we easily convert the value to a slide index
  const hasIntrapreneurGravity = intrapreneurScore > entrepreneurScore;

  // We'll keep an array of both types
  // so we can easily reverse the order
  const slides = [
    'entre',
    'intra'
  ];

  // Order the slides based on the user's gravity
  const orderedSlides = hasIntrapreneurGravity ? slides.reverse() : slides;

  return (
    <div 
      className={styles.EntreVsIntrapeneurs}
      data-cy='entre-vs-intrapeneurs-carousel'
      id='entre-vs-intrapeneurs-carousel'
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        { orderedSlides.map((slide, index) => {
          return (
            <SwiperSlide key={index}>
              <BlueCard type={slide} />
            </SwiperSlide>
          );
        }) }
      </Swiper>
    </div>
  );
}

function Header ({ windowWidth }) {
  if ( windowWidth < 768 ) {
    return (
      <h2 className={styles.header}>
        <span>Entrepreneurs</span>
        <span>vs.</span>
        <span>Intrapreneurs</span>
      </h2>
    );
  } else {
    return (
      <h2 className={styles.header}>The Difference Between Entrepreneurs and Intrapreneurs</h2>
    );
  }
}

Header.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};