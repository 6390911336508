import PropTypes from 'prop-types';
import globalStyles from '../../styles/global.module.css';
import styles from './GravitationalIdeology.module.css';
import GravitationalIdeologyCard from '../../components/GravitationalIdeologyCard';
import GravitateCard from '../../components/GravitateCard';
import AblohQuoteCard from '../../components/AblohQuoteCard';
import atomIllustration from '../../assets/illustrations/atom.svg';
import linePattern1 from '../../assets/line-patterns/line-pattern-1.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function GravitationalIdeology() {
  const windowWidth = useWindowWidth();
  
  const containerStyles = globalStyles['container-width'] + ' ' + styles['gravitational-ideology'];

  return (<>
    <section
      data-cy='gravitational-ideology'
      className={containerStyles}
    >
      <GravitationalIdeologyCard windowWidth={windowWidth} />
      <AtomIllustration windowWidth={windowWidth} />
      <div className={styles.flexCards}>
        <GravitateCard windowWidth={windowWidth} />
        <AblohQuoteCard windowWidth={windowWidth} />
      </div>
      <LinePatternRight windowWidth={windowWidth} />
      <LinePatternLeft windowWidth={windowWidth} />
    </section>
    <hr className={styles.hr} />
  </>);
}

function AtomIllustration({ windowWidth }) {
  if ( windowWidth < 1440 ) {
    return null;
  }

  return (
    <img
      src={atomIllustration}
      alt='Atom Illustration'
      className={styles.AtomIllustration}
    />
  );
}

AtomIllustration.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

function LinePatternRight({ windowWidth }) {
  if ( windowWidth < 768 ) {
    return null;
  }

  return (
    <img
      src={linePattern1}
      alt='Line Pattern'
      className={styles.LinePatternRight}
    />
  );
}

LinePatternRight.propTypes = {
  windowWidth: PropTypes.number.isRequired
};

function LinePatternLeft({ windowWidth }) {
  if ( windowWidth < 1440 ) {
    return null;
  }

  return (
    <img
      src={linePattern1}
      alt='Line Pattern'
      className={styles.LinePatternLeft}
    />
  );
}

LinePatternLeft.propTypes = {
  windowWidth: PropTypes.number.isRequired
};
