import { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './BlueCard.module.css';
import { ResultsContext } from '../../context/ResultsContext';

const options = {
  entre: {
    title: 'Entrepreneurial',
    body: 'Entrepreneurs thrive in blank canvases where they are given the permission to roam freely. The absence of preconceived expectations and limitations allow these innovators to challenge old thinking, to jump the cliff, and to face failure. Released from the constraints of established rules and undeterred in new territory, entrepreneurs tackle innovation by ignoring societal norms and coloring outside the lines.'
  },
  intra: {
    title: 'Intrapreneurial',
    body: 'Intrapreneurs flourish in structural organizations where the foundational bones are already in place. The skeletons for success are still intact and allows for the innovator to build upon, to expand reach, and to experiment with intention. Free from the burden of logistical responsibilities and safe from high-risk situations, intrapreneurs tackle innovation by unearthing new possibilities within their expertise.'
  }
};

export default function BlueCard({ type }) {

  const {
    intrapreneurScore,
    entrepreneurScore,
  } = useContext(ResultsContext);

  const { title, body } = options[type];

  const hasGravity = type === 'entre' ? entrepreneurScore >= intrapreneurScore : intrapreneurScore > entrepreneurScore;

  return (
    <div 
      className={cn([
        'text-2xl text-slate-900', 
        // hasGravity ? '' : '',
      ])}
    >
      <div 
        className={cn([
          'flex rounded-xl py-6',
          hasGravity ? 'bg-white shadow' : '',
        ])}
      >
        <div className="flex flex-col sr-only lg:not-sr-only">
          <div 
            className={cn([
              'flex flex-col flex-shrink w-1/2 rounded-r-full gap-6 p-8', 
              hasGravity ? 'text-innovation-blue bg-skyblue' : 'text-gray-500',
            ])}
          >
            <p 
              className={styles.subheading}
              style={{ opacity: Number( hasGravity ) }}
            >You gravitate to be</p>
            <h4 className={styles.heading}>{ title }</h4>
          </div>
          <p className={ 'p-8' }>{ body }</p>
        </div>
      </div>
    </div>
  );
}

BlueCard.propTypes = {
  type: PropTypes.string.isRequired,
};