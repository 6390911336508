import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CCC_Style_Card.module.css';
import communicationIcon from '../../assets/icons/communication.svg';
import collaborationIcon from '../../assets/icons/collaboration.svg';
import compromiseIcon from '../../assets/icons/compromise.svg';
import featureIcon from '../../assets/icons/featured-icon_green.svg';
import caret from '../../assets/icons/caret-down.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

const iconMap = {
  'Communication': communicationIcon,
  'Collaboration': collaborationIcon,
  'Compromise': compromiseIcon,
};

export default function CCC_Style_Card({ type, result, resultInfo }) {
  const windowWidth = useWindowWidth();
  const [ isOpen, setIsOpen ] = useState(false);

  function toggleIsOpen() {
    setIsOpen(isOpen => !isOpen);
  }

  const icon = iconMap[type];
  const resultHeader = type === 'Compromise' ? `${result} Willingness` : result;
  return (
    <div className={styles.CCC_Style_Card}>
      <div 
        className={`${styles.cardSection} ${styles.head}`}
        onClick={ windowWidth < 768 ? toggleIsOpen : null }
      >
        <img
          className={styles.headIcon}
          src={ icon }
          alt=''
        />
        <h5>{ type } Style</h5>
        <Caret
          active={isOpen}
          windowWidth={windowWidth}
        />
      </div>
      <div className={`${styles.subsections} ${isOpen ? styles.active : null}`}>
        <div className={`${styles.cardSection} ${styles.description}`}>
          <FeatureIcon />
          <div className={styles.textContainer}>
            <h6>{ resultHeader }</h6>
            <p>{ resultInfo.supportingText }</p>
          </div>
        </div>
        <div className={`${styles.cardSection} ${styles.strengths}`}>
          <FeatureIcon />
          <div className={styles.textContainer}>
            <h6>Strengths</h6>
            <ul className={styles.list}>
              {
                resultInfo.strengths.map( strength => {
                  return (
                    <li key={strength}>{strength}</li>
                  );
                })
              }
            </ul>
          </div>
        </div>
        <div className={`${styles.cardSection} ${styles.waysToGetBetter}`}>
          <HowToGetBetterFeatureIcon windowWidth={windowWidth} />
          <div className={styles.textContainer}>
            <h6>How to get 2% better</h6>
            <ul className={styles.list}>
              {
                resultInfo.waysToGetBetter.map( tip => {
                  return (
                    <li key={tip}>{tip}</li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

CCC_Style_Card.propTypes = {
  type: PropTypes.string.isRequired,
  result: PropTypes.string.isRequired,
  resultInfo: PropTypes.shape({
    supportingText: PropTypes.string.isRequired,
    strengths: PropTypes.arrayOf(PropTypes.string).isRequired,
    waysToGetBetter: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

function FeatureIcon() {
  return (
    <img
      className={styles.featureIcon}
      src={ featureIcon }
      alt=''
    />
  );
}

function Caret({ active, windowWidth }) {
  if ( windowWidth >= 768 ) {
    return null;
  }
  return (
    <img
      className={`${styles.caret} ${active ? styles.rotate180 : null}`}
      src={ caret }
      alt=''
    />
  );
}

Caret.propTypes = {
  active: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

function HowToGetBetterFeatureIcon({ windowWidth }) {
  if ( windowWidth < 992 ) {
    return null;
  }
  return <FeatureIcon />;
}

HowToGetBetterFeatureIcon.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};