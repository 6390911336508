import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const API_URL = '/.netlify/functions';

async function getUserResults() {
  // Get ID from result param in URL
  const resultParam = window.location.search.split('result=')[1];
  const resultId = resultParam ? resultParam : localStorage.getItem('resultId');
  if (!resultId) {
    return { loading: false, error: 'No Result ID' };
  }
  
  try {
    const response = await fetch(`${API_URL}/result?result=${resultId}`);
    const results = await response.json();
    localStorage.setItem('resultId', resultId);
    return { ...results, loading: false, error: false };
  } catch (error) {
    console.error(error);
    return { ...defaultState, loading: false, error: 'Results Fetch Failed' };
  }
}

export const ResultsContext = createContext();

const ResultsProvider = ({ children }) => {
  const [results, setResults] = useState({ ...defaultState, loading: true, error: null });

  useEffect(() => {
    getUserResults().then(setResults);
  }, []);

  return (
    <ResultsContext.Provider value={results}>
      {children}
    </ResultsContext.Provider>
  );
};

ResultsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultState = {
  'firstName': '',
  'lastName': '',
  'dominantType': '',
  'dominantScore': 0,
  'subDominantType': '',
  'subDominantScore': 0,
  'innovationMindset': '',
  'communicationStyle': '',
  'collaborationStyle': '',
  'willingnessToCompromise': '',
  'lowRiskScore': 0,
  'lowRewardScore': 0,
  'lowResponsibilityScore': 0,
  'highRiskScore': 0,
  'highRewardScore': 0,
  'highResponsibilityScore': 0,

  'intrapreneurScore': 0,
  'entrepreneurScore': 0,
};

export default ResultsProvider;