import PropTypes from 'prop-types';
import styles from '../SuccessCircles.module.css';
import { sortComponentsByWindowWidth } from '../../../utils';
import ProgressCircle from '../ProgressCircle';

export default function CirclesCard1({ windowWidth }) {
  return (
    <div className={ `${styles.circlesCard} ${styles.circlesCard1}` }>
      { sortComponentsByWindowWidth( viewportComponentMapping, windowWidth ) }
    </div>
  );
}

CirclesCard1.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

function Header() {
  return <h6>Without the ACT Model</h6>;
}

function Text() {
  return <p>{`Since 2016, we've launched national hackathons for teams to form and test ideas.`}</p>;
}

function Circle() {
  return (
    <div className={ styles.circle }>
      <ProgressCircle 
        score={2}
        year={'2016'}
      />
    </div>
  );
}

const viewportComponentMapping = {
  375: [
    <Header key='cc-header' />,
    <Text key='cc-text' />,
    <Circle key='cc-circle' />
  ],
  768: [
    <Circle key='cc-circle' />,
    <div key='cc-header-text'>
      <Header key='cc-header' />
      <Text key='cc-text' />
    </div>
  ],
  992: [
    <Header key='cc-header' />,
    <Circle key='cc-circle' />,
    <Text key='cc-text' />
  ]
};