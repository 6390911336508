import { useContext } from 'react';
import styles from './CCC_Style_Cards.module.css';
import CCC_Style_Card from '../CCC_Style_Card';
import ResultsError from '../ResultsError';
import { ResultsContext } from '../../context/ResultsContext';
import resultMap from '../../utils/CCC_Style-result-map.json';

export default function CCC_Style_Cards() {
  const results = useContext(ResultsContext);
  
  if ( results.loading ) {
    return null;
  }

  if ( results.error ) {
    return <ResultsError />;
  }

  if ( results.collaborationStyle === 'TaskOriented' ) {
    results.collaborationStyle = 'Task-oriented';
  } else if ( results.collaborationStyle === 'PeopleOriented' ) {
    results.collaborationStyle = 'People-oriented';
  }

  const userResults = [
    {
      type: 'Communication',
      result: results.communicationStyle,
      resultInfo: resultMap.communication[results.communicationStyle],
    },
    {
      type: 'Collaboration',
      result: results.collaborationStyle,
      resultInfo: resultMap.collaboration[results.collaborationStyle],
    },
    {
      type: 'Compromise',
      result: results.willingnessToCompromise,
      resultInfo: resultMap.compromise[`${results.willingnessToCompromise} Willingness`],
    },
  ];

  return (
    <div className={styles.CCC_Style_Cards}>
      {
        userResults.map( result => {
          return (
            <CCC_Style_Card
              key={result.type}
              type={result.type}
              result={result.result}
              resultInfo={result.resultInfo}
            />
          );
        })
      }
    </div>
  );
}