import styles from './NowWhat.module.css';
import globalStyles from '../../styles/global.module.css';
import illustrationPortrait from '../../assets/illustrations/now-what-portrait.svg';
import illustrationLandscape from '../../assets/illustrations/now-what-landscape.svg';
import ShareModal from '../../components/ShareModal';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function NowWhat() {

  const containerStyles = styles.NowWhat + ' ' + globalStyles['container-width'];
  return (
    <section className={containerStyles}>
      <Illustration />
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h6 className={styles.subheader}>Now what?</h6>
          <h2 className={styles.header}>Find growth, strengthen connections, & build better teams.</h2>
        </div>
        <div className={styles.paragraphContainer}>
          <p className={styles.paragraph}>
            If there’s one thing we want you to take away from this, it’s that these results are for you to leverage as a tool for achieving growth, to build better relationships, and create stronger teams. Teams make dreams work. There is no need for your dreams to go to the grave. 
          </p>
          <p className={styles.paragraph}>
            The more you understand the nuances of your present stage, the more equipped you’ll be on taking which step to take next. Some of the biggest barriers in your life can start with one seed of doubt or misinformation. We want to break those barriers down and resow with seeds of reflection that’ll grow into action.
          </p>
          <p className={styles.paragraph}>
            This is merely one snapshot of your life, and there will be countless more. We’re grateful to have been a part of it, and we thank you for taking part in ours.
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <ShareButton />
          <DownloadButton />
        </div>
      </div>
    </section>
  );
}

function Illustration() {
  const windowWidth = useWindowWidth();

  if (windowWidth < 768 || windowWidth >= 1440) {
    return (
      <img 
        src={illustrationPortrait} 
        alt='Now What' 
        className={styles.illustration}
      />
    );
  } else {
    return (
      <img 
        src={illustrationLandscape} 
        alt='Now What' 
        className={styles.illustration}
      />
    );
  }
}

import { useState } from 'react';

function ShareButton() {
  const [modalActive, setModalActive] = useState(false);

  function toggleModal() {
    setModalActive(modalActive => !modalActive);
  }

  return (
    <div className={styles.shareButtonContainer}>
      <button
        className={styles.shareButton}
        onClick={toggleModal}
      >
        Share Your Results
      </button>
      {
        modalActive &&
        <ShareModal toggleModal={toggleModal} />
      }
    </div>
  );
}

function DownloadButton() {
  const windowWidth = useWindowWidth();

  if (windowWidth < 1024) {
    return <p className={styles.mobileDownloadText}>View on desktop to Download as PDF</p>;
  }

  function triggerPrint() {
    window.print();
  }

  return (
    <button 
      className={styles.downloadButton}
      onClick={triggerPrint}
    >
      Download as a PDF
    </button>
  );
}