import globalStyles from '../../styles/global.module.css';
import styles from './ResultsOverview.module.css';
import resultsOverviewIllustration from '../../assets/illustrations/overview-1.svg';
import OverviewCards from '../../components/OverviewCards';

export default function ResultsOverview() {
  const containerStyles = globalStyles['container-width'] + ' ' + styles.ResultsOverview;
  return (<>
    <section
      className={containerStyles}
      data-cy='results-overview'
    >
      <div 
        className={styles.flexContainerTop}
        data-cy='flex-container-top'
      >
        <img
          src={resultsOverviewIllustration}
          alt='Results Overview Illustration'
          className={styles.ResultsOverviewIllustration}
        />
        <div className={styles.overviewText}>
          <h2>Results Overview</h2>
          <p>Our intent is to not lock you in a box. Rather, we want to supply you the language to communicate your essence and the tools to begin your journey and evolution.</p>
          <ReminderLabel />
        </div>
      </div>
      <OverviewCards />
    </section>
    <hr 
      className={styles.hr} 
      data-cy='results-overview-hr'
    />
  </>);
}


import useWindowWidth from '../../hooks/useWindowWidth';

function ReminderLabel() {
  const width = useWindowWidth();
  if ( width < 768 ) {
    return null;
  }

  return (
    <div 
      className={styles.ReminderLabel}
      data-cy='reminder-label'
    >
      <span>Remember to lean in and{' '}</span>
      <span className={styles.bold}>act different.</span>
    </div>
  );
}