import globalStyles from '../../styles/global.module.css';
import styles from './EntreIntraPairCards.module.css';
import './EntreIntraPairCards.css';

export default function EntreIntraPairCards() {
  const containerStyles = globalStyles['container-width'];
  return (
    <section className={containerStyles}>
      <h2 className={styles.header}>Who are the innovators of this generation?</h2>
      <Carousel />
    </section>
  );
}

import { useRef } from 'react';
import EntreIntraPairCard from '../../components/EntreIntraPairCard';
import carouselArrow from '../../assets/icons/carousel-arrow.png';
// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import entreIntrapeneurPairs from '../../utils/entreIntrapeneurPairs.js'; 

function Carousel() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <div 
      className={styles.EntreIntraPairCards}
      data-cy='entra-intra-pairs-carousel'
      id='entra-intra-pairs-carousel'
    >
      <Swiper
        loop={true}
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {/* Need to add a SwiperSlide for each entreIntrapeneurPair. Could not find fix for swiper to dynamically add slides */}
        <SwiperSlide>
          <EntreIntraPairCard entreIntrapeneurPair={entreIntrapeneurPairs[0]} />
        </SwiperSlide>
        <SwiperSlide>
          <EntreIntraPairCard entreIntrapeneurPair={entreIntrapeneurPairs[1]} />
        </SwiperSlide>
        <SwiperSlide>
          <EntreIntraPairCard entreIntrapeneurPair={entreIntrapeneurPairs[2]} />
        </SwiperSlide>
        <div className={styles.arrowContainer}>
          <img 
            ref={prevRef}
            src={carouselArrow}
            alt='carousel-arrow'
            className={styles.carouselArrow}
          />
          <img 
            ref={nextRef}
            src={carouselArrow}
            alt='carousel-arrow'
            className={`${styles.carouselArrow} ${styles.carouselArrowReverse}`}
          />
        </div>
      </Swiper>
    </div>
  );
}