import PropTypes from 'prop-types';
import styles from './ShareModal.module.css';

export default function ShareModal({ toggleModal }) {
  return (
    <div className={styles.shareModal}>
      <ShareCard toggleModal={toggleModal} />
    </div>
  );
}

ShareModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

function ShareCard({ toggleModal }) {
  const resultId = localStorage.getItem('resultId');
  const url = `${window.location.origin}/?result=${resultId}`;
  
  return (
    <div className={styles.shareModalCard}>
      <p className={styles.shareHeader}>Share a link to your results:</p>
      <div className={styles.shareTextarea}>
        <p>{ url }</p>
      </div>
      <button 
        className={styles.closeButton}
        onClick={ toggleModal }
      >
        Close
      </button>
    </div>
  );
}

ShareCard.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};