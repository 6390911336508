import styles from './SuccessRates.module.css';
import SuccessCircles from '../../components/SuccessCircles';

export default function SuccessRates() {
  return (
    <section className={ styles.SuccessRates }>
      <Header />
      <SuccessCircles />
    </section>
  );
}

import headerIllustration from '../../assets/illustrations/timer-illustration.svg';

function Header() {
  return (
    <div className={ styles.headerContainer }>
      <div className={ styles.iconContainer }>
        <img
          src={ headerIllustration }
          alt='Team Cohesion Quotient'
          className={ styles.icon }
        />
      </div>
      <div>
        <h4 className={ styles.header }>There’s no secret ingredient - just one formula that’s been perfected over time.</h4>
        <p className={ styles.subheader }>Over the past 5 years, we have been able to observe Architects, Creatives and Techies do what they do best - build, innovate and create. We have watched them individually and also within teams that accelerate their creative productivity.</p>
      </div>
    </div>
  );
}