import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import Error from './components/Error';
// Sections
import Overview from './sections/Overview';
import ArchetypeDiscovery from './sections/ArchetypeDiscovery';
import InnovationMindset from './sections/InnovationMindset';
import TeamCohesion from './sections/TeamCohesion';
import NowWhat from './sections/NowWhat';
// Styles
import './App.css';
// Context
import { useContext } from 'react';
import { ResultsContext } from './context/ResultsContext';

function App() {
  const results = useContext(ResultsContext);

  if (results.loading) {
    return <LoadingSpinner />;
  }
  if (results.error) {
    if ( results.error === 'No Result ID' ) {
      return <Error message="There doesn't appear to be a result ID attached to your URL." />;
    }
    return <Error message='It looks like there was an error when loading your results. Don’t take it personally though.' />;
  }
  
  return (
    <div className="App">
      <Navbar />
      <main>
        <Overview />
        <ArchetypeDiscovery />
        <InnovationMindset />
        <TeamCohesion />
        <NowWhat />
        <Footer />
      </main>
    </div>
  );
}

export default App;
