import PropTypes from 'prop-types';
import styles from './EntreIntraPairCard.module.css';
// import dottedLine from '../../assets/line-patterns/dotted-line.svg';

export default function EntreIntraPairCard({ entreIntrapeneurPair }) {
  const { entrepeneur, intrapreneur } = entreIntrapeneurPair;

  return (
    <div className={ styles.EntreIntraPairCard }>
      <div className={ styles.innerCard }>
        <img
          className={ styles.cardImage }
          src={ entrepeneur.image }
          alt={ entrepeneur.name }
        />
        <div className={styles.label}>
          <p>Entrepreneur</p>
        </div>
        <h4>{ entrepeneur.name }</h4>
        <p>{ entrepeneur.description }</p>
      </div>
      <div className={styles.dottedLine} />
      <div className={ styles.innerCard }>
        <img
          className={ styles.cardImage }
          src={ intrapreneur.image }
          alt={ intrapreneur.name }
        />
        <div className={styles.label}>
          <p>Intrapreneur</p>
        </div>
        <h4>{ intrapreneur.name }</h4>
        <p>{ intrapreneur.description }</p>
      </div>
    </div>
  );
}

EntreIntraPairCard.propTypes = {
  entreIntrapeneurPair: PropTypes.shape({
    entrepeneur: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    intrapreneur: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};