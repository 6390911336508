import magnifyingGlassIllustration from '../../assets/illustrations/magnifying-glass.svg';
import styles from './MagnifyingGlass.module.css';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function MagnifyingGlass() {
  const windowWidth = useWindowWidth();

  if (windowWidth < 1440) {
    return null;
  }
  return (
    <img
      src={magnifyingGlassIllustration}
      alt="Magnifying Glass"
      className={styles.MagnifyingGlass}
      data-cy='magnifier-illustration'
    />
  );
}