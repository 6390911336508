import { useState, useEffect } from 'react';
import styles from './Navbar.module.css';
import PropTypes from 'prop-types';
import useWindowWidth from '../../hooks/useWindowWidth';

import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import logo from './img/act-house.png';
import linkedin from './img/linkedin.png';
import twitter from './img/twitter.png';
import instagram from '../../assets/icons/instagram.svg';
import hamburger from '../../assets/icons/hamburger.svg';

export default function Navbar() {
  const width = useWindowWidth();

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(prev => !prev);
  };

  const closeToggle = () => {
    setNavbarOpen(false);
  };

  return (<>
    <Router>
      <nav
        className={navbarOpen ? styles.navbarMainActive : styles.navbarMain }
      >
        <Container
          className={styles.mobileContainer}>
          <Logo/>
          { width >= 1440 && <ListItems />}
          { width >= 1440 && <SocialMedia />}
          <Hamburger
            handleToggle={handleToggle}
            width={width}
          />
        </Container>
        { width < 1440 && <MobileList closeToggle={closeToggle}/>}
      </nav>
    </Router>
  </>);
}
Navbar.propTypes = {
  handleToggle: PropTypes.func,
  closeToggle: PropTypes.func
};

function Hamburger ({handleToggle, width}) {
  if( width >= 1440 ) {
    return;
  }
  return(
    <div className={styles.hamburgerContainer}>
      <button
        className={styles.hamburgerButton}
        aria-label='menu'
      >
        <img
          className={styles.hamburger}
          src={hamburger}
          alt='hamburger'
          onClick={handleToggle}
        />
      </button>
    </div>
  );
}
Hamburger.propTypes = {
  handleToggle: PropTypes.func,
  width: PropTypes.number.isRequired
};


function Logo () {
  return(
    <a
      href='https://www.act.house/act-assessment'
      target='_blank'
      rel='noopener noreferrer'
      className={styles.logoLink}
    >
      <img
        src={logo}
        alt="act assessment logo"
        className={styles.logo}
      />
    </a>
  );
}

function MobileList ({closeToggle}) {

  return(
    <Container
      className={styles.listContainer}>
      <ListItems closeToggle={closeToggle}/>
      <SocialMedia />
    </Container>
  );
}

MobileList.propTypes = {
  closeToggle: PropTypes.func
};

function SocialMedia() {

  return(
    <Container
      className={styles.socialContainer}>
      <span>Follow Us:</span>
      <ul>
        <li>
          <button aria-label='ACT linked in'>
            <a
              href='https://www.linkedin.com/company/theacthouse/'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={linkedin} />
            </a>
          </button>
        </li>
        <li>
          <button aria-label='ACT twitter'>
            <a
              href='https://www.twitter.com/theacthouse'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={twitter} />
            </a>
          </button>
        </li>
        <li>
          <button aria-label='ACT instagram'>
            <a
              href='https://www.instagram.com/theacthouse/'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={instagram}
                className={styles.insta} />
            </a>
          </button>
        </li>
      </ul>
    </Container>
  );
}

function ListItems({closeToggle}) {

  let location = useLocation();

  // pass to links
  var lists = [
    { text: 'Overview', href: '/#overview', id: 0, section: 'overview' },
    { text: 'Archetype Discovery', href: '/#archetypeDiscovery', id: 1, section: 'archetypeDiscovery' },
    { text: 'Innovation Mindset', href: '/#innovationMindset', id: 2, section: 'innovationMindset' },
    { text: 'Team Cohesion', href: '/#teamCohesion', id: 3, section: 'teamCohesion' }
  ];
  const active = styles.navTextActive;

  useEffect(() => {
    const sections = document.querySelectorAll('section.screen');
    const config = {
      rootMargin: '0px'
    };

    const activeList = styles.navTextActive;

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          intersectionHandler(entry);
        }
      });
    }, config);

    sections.forEach(section => {
      observer.observe(section);
    });

    function intersectionHandler(entry) {
      const id = entry.target.id;
      const currentlyActive = document.querySelector('nav li.' + activeList );
      const shouldBeActive = document.querySelector('nav li[data-ref=' + id + ']');

      if (currentlyActive) {
        currentlyActive.classList.remove(activeList);
      }
      if (shouldBeActive) {
        shouldBeActive.classList.add(activeList);
      }
    }
  }, []);

  return (
    <Container
      className={styles.contentContainer}
    >
      <ul>
        {lists.map(list => {

          const section = list.section;
          return (
            <li
              key={list.id}
              className={styles.navItem}
              data-ref={section}
            >
              <NavHashLink
                to={list.href}
                className={`${location.pathname}${location.hash}` === list.href ? active : ''}
                style={list.customStyle}
                onClick={closeToggle}
              >
                {list.text}
              </NavHashLink>
            </li>
          );
        })}
      </ul>
    </Container>
  );
}
ListItems.propTypes = {
  closeToggle: PropTypes.func
};

const Container = (props) => {
  const classes = props.className;

  return <div className={classes}>{props.children}</div>;
};
Container.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
