import PropTypes from 'prop-types';
import styles from './ArchetypeCard.module.css';
import architectIcon from '../../assets/icons/architect-icon_purple.svg';
import creativeIcon from '../../assets/icons/creative-icon_purple.svg';
import techieIcon from '../../assets/icons/techie-icon_purple.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

const dominantSubHeaders = {
  'Architect': 'Architects are pragmatic visionaries with a business mindset. ',
  'Creative': 'Dominant Creatives breathe life into any blueprint.',
  'Techie': 'Dominant Techies are the makers and doers.',
};
const subdominantSubHeaders = {
  'Architect': 'Subdominant Architects love planning ahead and recognizing hidden opportunities.',
  'Creative': 'Subdominant Creatives are in tune with their desire to build.',
  'Techie': 'Subdominant Techies are low-key obsessed with figuring out why a problem is a problem.',
};
const dominantDescriptions = {
  'Architect': 'Their passion is cultivated from a desire to serve their team, their customers, and their vision. They internalize a competitive spirit and an ambitious drive that fuel their motivation for building relationships and sharing high-level strategy. At the highest level, their ability to dissect human-centric puzzles turns into a superpower that can inspire and lead others.',
  'Creative': 'Their innate ability to visualize story, build details, and layer design gives them the power to create aesthetic pleasing and interactive solutions. Through the use of best practice tactics and inspired by strong narratives, they can clearly repurpose an abstract idea into a tangible product. At their highest form, they serve as a bridge between business goals and technical execution.',
  'Techie': 'Their primary role and responsibility is to bring things to life. Techies are intimately interested in how things function, how things can be broken, and how to fix them. In short, they make things work. They have a strong analytical skill set and obsessive mindset to solve problems. At their highest form, Techies use their skills to look past barriers and uncover the best solution in terms of efficiency and practicality.',
};
const subdominantDescriptions = {
  'Architect': 'Wielding a high level of emotional intelligence, you can read situations and people easily. As a people-focused and process-centered planner, Architects flourish when faced with the opportunity to solve problems that serve their community.',
  'Creative': 'Fueled by their natural urge to create, they find refuge in exploring different outlets to transform emotion into art, thought into story, and passion into product. Subdominant Creatives love to lead with familiar instincts and can create strong bonds with their creations. However, they also tend to find pleasure in disturbing the status quo and breaking expectations.',
  'Techie': 'They geek out on all things operational because they need to know not only how things work, but why they work, and if they can be improved. While others take things at face value, Techies are naturally inquisitive and view the world with a systematic lens, so they like to put on their investigative hat to tackle problems.',
};

export default function ArchetypeCard({ archetype, dominance, score }) {
  const windowWidth = useWindowWidth();

  const icon = getIconByArchetype(archetype);
  const subHeader = dominance === 'Dominant' ? dominantSubHeaders[archetype] : subdominantSubHeaders[archetype];
  const description = dominance === 'Dominant' ? dominantDescriptions[archetype] : subdominantDescriptions[archetype];

  return (
    <div 
      className={`${styles.ArchetypeCard} ${styles[dominance]}`}
      data-cy='archetype-card'
    >
      <div className={styles.topRow}>
        <img
          src={icon}
          alt={`${dominance} Archetype`}
          className={styles.icon}
        />
        { windowWidth >= 768 && <Header 
          dominance={dominance} 
          archetype={archetype} /> 
        }
        {/* Score */}
        <div className={styles.scoreContainer}>
          <span>Your Score</span>
          <span 
            className={styles.score}
            data-cy='score'
          >{ score }</span>
        </div>
      </div>
      <div className={styles.body}>
        { windowWidth < 768 && <Header 
          dominance={dominance} 
          archetype={archetype} /> 
        }
        <p className={styles.subHeader}>{ subHeader }</p>
        <p className={styles.description}>{ description }</p>
      </div>
    </div>
  );
}

ArchetypeCard.propTypes = {
  archetype: PropTypes.string.isRequired,
  dominance: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

function Header({ dominance, archetype }) {
  return (
    <div className={styles.header}>
      <h5 data-cy='dom-subdom-label'>Your {dominance} Archetype</h5>
      <h2>{ archetype }</h2>
    </div>
  );
}

Header.propTypes = {
  dominance: PropTypes.string.isRequired,
  archetype: PropTypes.string.isRequired,
};

function getIconByArchetype(archetype) {
  switch (archetype) {
    case 'Architect':
      return architectIcon;
    case 'Creative':
      return creativeIcon;
    case 'Techie':
      return techieIcon;
    default:
      return null;
  }
}