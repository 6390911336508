import styles from './SkillsTalentsRankCards.module.css';
import skillsIllustration from '../../assets/icons/skills.svg';
import talentsIllustration from '../../assets/icons/talents.svg';
import rankIllustration from '../../assets/icons/rank.svg';

export default function SkillsTalentsRankCards() {
  return (
    <div
      className={styles.SkillsTalentsRankCards}
      data-cy='skills-talents-rank-cards'
    >
      <div 
        className={styles.card}
        data-cy='skills-card'
      >
        <img
          src={skillsIllustration}
          alt='Skills illustration'
          className={styles.illustration}
        />
        <h6 className={styles.header}>Skills</h6>
        <p className={styles.body}>Your tactical abilities you&apos;ve mastered and sharpened by focused training and extensive practice.</p>
      </div>
      <div 
        className={styles.card}
        data-cy='talents-card'
      >
        <img
          src={talentsIllustration}
          alt='Talents illustration'
          className={styles.illustration}
        />
        <h6 className={styles.header}>Talents</h6>
        <p className={styles.body}>Your inner superpower waiting to be rekindled. Reach the next level by clarifying your strengths and pursuing personalized coaching.</p>
      </div>
      <div 
        className={styles.card}
        data-cy='rank-card'
      >
        <img
          src={rankIllustration}
          alt='Rank illustration'
          className={styles.illustration}
        />
        <h6 className={styles.header}>Rank</h6>
        <p className={styles.body}>The current stage of your combined skills and talents. Want to level up? Practice makes progress.</p>
      </div>
    </div>
  );
}