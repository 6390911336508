/** !!! REMINDER: Resizing is not a common event!! 
* Ignore the feeling that you need to spend hours refactoring to avoid rerender on every resize event */
/**
 * - Import useWindowWidth hook into file you want to use it in
 * import useWindowWidth from '../hooks/useWindowWidth';
 * 
 * - Invoke inside component
 * const width = useWindowWidth();
 * 
 * NOTE: This will cause a rerender of the component every time the window width changes,
 * use at the lowest possible level of component tree to avoid rerendering anything other than the necessary components.
 */

import { useState, useEffect } from 'react';

export default function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}