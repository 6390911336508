import styles from './WellBalancedRecipe.module.css';
import headerImg from '../../assets/illustrations/tc-well-balanced-recipe.svg';
import CCC_Cards from '../../components/CCC_Cards';

export default function WellBalancedRecipe() {
  return (
    <section className={styles.WellBalancedRecipe}>
      <div className={styles.headerContainer}>
        <img
          className={styles.headerImg}
          src={headerImg}
          alt="Well Balanced Recipe"
        />
        <div className={styles.textContainer}>
          <h2>Team Cohesion needs a well-balanced recipe.</h2>
          <p>We believe Team Cohesion is the result of understanding your team members’ Communication preferences, Collaborative styles and Compromising tendencies.</p>
        </div>
      </div>
      <CCC_Cards />
    </section>
  );
}