import { useContext } from 'react';
import { ResultsContext } from '../../context/ResultsContext';
import PropTypes from 'prop-types';
import globalStyles from '../../styles/global.module.css';
import styles from './RiskRewardResponsibilityCards.module.css';
import './RiskRewardResponsibilityCards.css';
import RiskRewardResponsibilityCard from '../../components/RiskRewardResponsibilityCard';
import useWindowWidth from '../../hooks/useWindowWidth';
// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

export default function RiskRewardResponsibilityCards() {
  const windowWidth = useWindowWidth();
  const {
    highRiskScore, 
    highRewardScore, 
    highResponsibilityScore 
  } = useContext(ResultsContext);
  
  const containerStyles = globalStyles['container-width'];

  return (
    <section className={containerStyles}>
      <div className={styles.flexText}>
        <p className={styles.bold}>We found 63 attributes of what makes an Entreprenuer and Intrapreneur. But we believe these 3 carry the most impact.</p>
        <p className={styles.grayText}>We all are capable of innovating--many of us just have our own preference, our own path, and our own purpose, and we have boiled that down to three distinct things.</p>
      </div>
      { windowWidth < 768 ?
        <Carousel
          highRiskScore={highRiskScore}
          highRewardScore={highRewardScore}
          highResponsibilityScore={highResponsibilityScore} 
        />
        :
        <div className={styles.cardContainer}>
          <RiskRewardResponsibilityCard
            attribute='Risk'
            score={highRiskScore}
          />
          <RiskRewardResponsibilityCard
            attribute='Reward'
            score={highRewardScore}
          />
          <RiskRewardResponsibilityCard
            attribute='Responsibility'
            score={highResponsibilityScore}
          />
        </div>
      }
    </section>
  );
}

function Carousel({ highRiskScore, highRewardScore, highResponsibilityScore }) {
  return (
    <div 
      className={styles.RiskRewardResponsibilityCards}
      data-cy='rrr-cards-carousel'
      id='rrr-cards-carousel'
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        <SwiperSlide className={styles.SwiperSlide}>
          <RiskRewardResponsibilityCard
            attribute='Risk'
            score={highRiskScore}
          />
        </SwiperSlide>
        <SwiperSlide>
          <RiskRewardResponsibilityCard
            attribute='Reward'
            score={highRewardScore}
          />
        </SwiperSlide>
        <SwiperSlide>
          <RiskRewardResponsibilityCard
            attribute='Responsibility'
            score={highResponsibilityScore}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

Carousel.propTypes = {
  highRiskScore: PropTypes.number.isRequired,
  highRewardScore: PropTypes.number.isRequired,
  highResponsibilityScore: PropTypes.number.isRequired,
};