import { useContext } from 'react';
import { ResultsContext } from '../../context/ResultsContext';
import styles from './SuperstarPairingCard.module.css';
import architectIcon from '../../assets/icons/architect-icon_full.svg';
import creativeIcon from '../../assets/icons/creative-icon_full.svg';
import techieIcon from '../../assets/icons/techie-icon_full.svg';
import dashedLine from '../../assets/line-patterns/dotted-line.svg';
import dashedLineLong from '../../assets/line-patterns/dotted-line-long.svg';
import ResultsError from '../ResultsError';

const iconByType = {
  'Architect': architectIcon,
  'Creative': creativeIcon,
  'Techie': techieIcon,
};

const archetypeMatchMap = {
  'Architect': ['Creative', 'Techie'],
  'Creative': ['Architect', 'Techie'],
  'Techie': ['Architect', 'Creative'],
};

export default function SuperstarPairingCard() {
  const results = useContext(ResultsContext);

  if ( results.loading ) {
    return null;
  }
  if ( results.error ) {
    return <ResultsError />;
  }

  const { dominantType, subDominantType } = results;
  const domIcon = iconByType[dominantType];
  const subDomIcon = iconByType[subDominantType];
  const archetypeMatches = [
    {
      type: archetypeMatchMap[dominantType][0],
      icon: iconByType[archetypeMatchMap[dominantType][0]],
    },
    {
      type: archetypeMatchMap[dominantType][1],
      icon: iconByType[archetypeMatchMap[dominantType][1]],
    },
  ];

  return (
    <div className={styles.SuperstarPairingCard}>
      <h3 className={styles.header}>What superstars you pair best with</h3>
      <div className={styles.contentContainer}>
        <div className={styles.userArchetypes}>
          <div className={`${styles.archetype} ${styles.dominantArchetype}`}>
            <div className={styles.imageContainer}>
              <img
                src={domIcon}
                alt='Dom'
                className={styles.archetypeImage}
              />
            </div>
            <div className={styles.archetypeText}>
              <h4>{ dominantType }</h4>
              <p>Dominant Archetype</p>
            </div>
          </div>
          <div className={`${styles.archetype} ${styles.subdominantArchetype}`}>
            <div className={styles.imageContainer}>
              <img
                src={subDomIcon}
                alt='Subdom'
                className={styles.archetypeImage}
              />
            </div>
            <div className={styles.archetypeText}>
              <h4>{ subDominantType }</h4>
              <p>Sub Dominant Archetype</p>
            </div>
          </div>
        </div>
        <DashedLine />
        <div className={styles.matchingArchetypes}>
          <div className={styles.matchingArchetype}>
            <img
              src={archetypeMatches[0].icon}
              alt={`Match type: ${archetypeMatches[0].type}`}
              className={styles.archetypeMatchImage}
            />
            <div className={styles.archetypeMatchText}>
              <h4>{ archetypeMatches[0].type }</h4>
            </div>
          </div>
          <div className={styles.matchingArchetype}>
            <img
              src={archetypeMatches[1].icon}
              alt={`Match type: ${archetypeMatches[1].type}`}
              className={styles.archetypeMatchImage}
            />
            <div className={styles.archetypeMatchText}>
              <h4>{ archetypeMatches[1].type }</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import useWindowWidth from '../../hooks/useWindowWidth';

function DashedLine() {
  const windowWidth = useWindowWidth();

  const correctImg = windowWidth > 768 ? dashedLineLong : dashedLine;
  return (
    <div className={styles.lineContainer}>
      <img
        src={correctImg}
        alt='Dashed Line'
        className={styles.dashedLine}
      />
    </div>
  );
}