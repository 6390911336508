import { TailSpin as LoaderSpinner } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import styles from './LoadingSpinner.module.css';

export default function LoadingSpinner({ height = '100', width = '100', color = '#EB4F28' }) {
  return (
    <div className={styles.LoadingSpinner}>
      <LoaderSpinner
        height={height}
        width={width}
        color={color}
        ariaLabel='loading'
      />
    </div>
  );
}

LoadingSpinner.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};