import PropTypes from 'prop-types';
import styles from './InnovationMindsetOverview.module.css';
import icon from './icons/innovation-mindset-icon.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

/* Icons */
import definingIcon from './icons/defining-innovation-icon.svg';
import entrepreneurialIcon from './icons/entreprenuerial-mindset-icon.svg';

const primaryTitle = {
  'Defining': 'Defining the Innovation Mindset',
  'Thought': 'I thought it was “Entrepreneurial Mindset”?'
};


export default function InnovationMindsetOverview() {

  return (<>
    <div
      className={styles.innovationMindsetOverview}
    >
      <Container
        className={styles.headline}>
        <Headline
          title='Innovation Mindset'
          description='What does your mind and body gravitate towards?'
        />
      </Container>
      <ContentContainer
        align='left'
        title={primaryTitle['Defining']}
        type='defining'
        firstImage={definingIcon}
        secondImage={definingIcon}
        show={true}
      />
      <ContentContainer
        align='right'
        title={primaryTitle['Thought']}
        type='thought'
        firstImage={entrepreneurialIcon}
        secondImage={entrepreneurialIcon}
        show={false}
      />
    </div>
  </>);
}
function ImageSwitch ({firstImage, secondImage, show}) {
  const width = useWindowWidth();

  if( width <= 375 && !show ) {
    return;
  }

  if( width >= 1440 ) {
    return(<img src={firstImage} />);
  }

  if( width >= 992) {
    return(<img src={secondImage} />);
  }

  return(<img src={firstImage} />);
}
ImageSwitch.propTypes = {
  firstImage: PropTypes.string.isRequired,
  secondImage: PropTypes.string.isRequired,
  show: PropTypes.bool
};

function ContentContainer ({align, title, firstImage, secondImage, show, type}) {
  const main = styles.contentContainer;
  const alignClass = align == 'right' ? styles.contentContainerRight : styles.contentContainerLeft;
  const classes = main + ' ' + alignClass;
  return(
    <div
      className={classes}
    >
      <div>
        <h3 className={styles.title}>{title}</h3>
        <ContentItem type={type} />
      </div>
      <ImageSwitch
        firstImage={firstImage}
        secondImage={secondImage}
        show={show}
      />
    </div>
  );
}
ContentContainer.propTypes = {
  align: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  firstImage: PropTypes.string.isRequired,
  secondImage: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};

function ContentItem ({type}) {
  const duo = styles.strong + ' ' + styles.italic;

  if( type === 'defining' ) {
    return (<>
      <p>The <span className={styles.strong}>Innovation Mindset</span> is the common denominator between <span className={duo}>Intrapreneurs</span>, those who are innovating within corporate spaces, and <span className={duo}>Entrepreneurs</span>, those who creating new corporations.</p>
      <p>To understand the intricacies of your mind, we must unveil how your impulses, habits and desires determine your optimal role in an innovative ecosystem.</p>
    </>);
  }

  if( type === 'thought' ) {
    return (<>
      <p>Often within innovation spaces, startups and corporate teams hear this term - “<span className={duo}>Entrepreneurial Mindset.</span>” Everyone in society wants to know how to develop entrepreneurial skills, yet not everyone is meant to be an entrepreneur.</p>
      <p>However, we all have the potential to be <span className={styles.strong}>innovative</span> - to create new products, introduce new ideas, or execute new strategies.</p>
    </>);
  }

  return;
}
ContentItem.propTypes = {
  type: PropTypes.string.isRequired
};

function Headline ({title, description}) {

  return(<>
    <img
      src={icon}
      alt='Innovation Mindset'
      className={styles.icon}
    />
    <div>
      <h2 className={styles.title}>{ title }</h2>
      <p className={styles.subtitle}>{ description }</p>
    </div>
  </>);
}
Headline.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const Container = (props) => {
  const classes = props.className + ' container';

  return <div className={classes}>{props.children}</div>;
};
Container.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
