import styles from './ACTModelExamples.module.css';
import ACTModelCards from '../../components/ACTModelCards';

export default function ACTModelExamples() {
  return (
    <div className={styles.ACTModelExamples}>
      <h2 className={styles.header}>ACT Model teams can be found in any and every industry.</h2>
      <p className={styles.subheader}>{`And no, these superstar teams haven't taken the assessment (yet 😉) BUT, yes.....BUT our team did our due diligence by astutely observing their positions,  performances and personalities to make an educated guess. Whether it’s the Powerpuff Girls, Charlie’s Angels, or the Bulls, triads are everywhere.`}</p>
      <ACTModelCards />
    </div>
  );
}