import styles from './SuperstarPairing.module.css';
import SuperstarPairingCard from '../../components/SuperstarPairingCard';

export default function SuperstarPairing() {
  return (
    <section className={styles.SuperstarPairing}>
      <div className={styles.header}>
        <h2>Diversity of expertise & experience breeds boundless innovation.</h2>
        <p>{`As an Architect, you map best with Creatives and Techies. Why? Because these archetypes wield different skills and talents that will complement your growth and strengthen your team's arsenal.`}</p>
      </div>
      <SuperstarPairingCard />
    </section>
  );
}