import globalStyles from '../../styles/global.module.css';
import styles from './ResultsAreIn.module.css';
import theHorns from '../../assets/icons/the-horns.png';

export default function ResultsAreIn() {
  const containerStyles = globalStyles['container-width'] + ' ' + styles.resultsAreIn;
  const questionContainerStyles = globalStyles['text-xl-normal'] + ' ' + styles['questionContainer'];
  
  return (
    <section 
      data-cy='results-are-in' 
      className={containerStyles}
    >
      {/* Pre-Header */}
      <p className={styles.preheader}>ACT Assessment Results</p>
      {/* Header */}
      <h2 className={styles.header}>
        <span>The results are in, fam!</span>
        <img
          src={theHorns}
          alt='the horns emoji'
          className={styles.the_horns}
        />
      </h2>
      {/* Question Container */}
      <div 
        data-cy='question-container'
        className={questionContainerStyles}
      >
        <p>
          Are you an Architect, Creative, or Techie?
        </p>
        <p>
          <span className={styles.bullet}> • </span>
          What&apos;s your Appetite for Risk, Desire for Reward, and Aptitude for Responsibility?
        </p>
        <p>
          <span className={styles.bullet}> • </span>
          What are your Communication, Collaboration and Compromise styles?
        </p>
      </div>
    </section>
  );
}