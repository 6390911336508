import PropTypes from 'prop-types';
import styles from './AblohQuoteCard.module.css';

export default function AblohQuoteCard({ windowWidth }) {
  return (
    <div
      className={styles.AblohQuoteCard}
      data-cy='quote-card'
    >
      { renderContent(windowWidth) }
    </div>
  );
}

AblohQuoteCard.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

function renderContent( windowWidth ) {
  if ( windowWidth < 1440 ) {
    return (<>
      <p className={styles.quote}>&quot;When creativity melds together with global issues, I believe you can bring the world together.&quot;</p>
      <p className={styles.source}>- Virgil Abloh</p>
    </>);
  } else {
    return (<>
      <p className={styles.quote}>&quot;When creativity melds together with global issues, I believe you can bring the world together.&quot; - Virgil Abloh</p>
    </>);
  }
}