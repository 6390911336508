import PropTypes from 'prop-types';
import styles from './RiskRewardResponsibilityCard.module.css';
import riskIcon from '../../assets/icons/risk.svg';
import rewardIcon from '../../assets/icons/reward.svg';
import responsibilityIcon from '../../assets/icons/responsibility.svg';

const dominantDescriptions = {
  'Risk': {
    preBoldText: 'Your concept of Risk is often influenced by past experiences, present knowledge and future security. How? Because those interpretations affect your perception of risk-taking - ',
    boldText: 'an uncertain gamble versus an unexpected opportunity.'
  },
  'Reward': {
    preBoldText: 'Reward can be powerful - it can provoke action, provide merit, and shape patterns. But only if its value matches the value of your work. In other words, Reward serves its purpose when it’s able to ',
    boldText: 'refuel you with the energy you lost in the work.'
  },
  'Responsibility': {
    preBoldText: 'Approaching Responsibility can feel motivating or discouraging, depending on the type of role you feel you need to fill. Your relationship with Responsibility can be ',
    boldText: 'a catalyst for your growth or for your downfall as you evolve.'
  },
};

export default function RiskRewardResponsibilityCard({ attribute, score }) {
  const icon = getIconByAttribute(attribute);
  const description = dominantDescriptions[attribute];

  return (
    <div 
      className={styles.RiskRewardResponsibilityCard}
      data-cy='rrr-card'
    >
      <div className={styles.topRow}>
        <img
          src={icon}
          alt={`${attribute} Attribute`}
          className={styles.icon}
        />
        {/* Score */}
        <div className={styles.scoreContainer}>
          <span>Your Score</span>
          <span 
            className={styles.score}
            data-cy='score'
          >{ score }</span>
        </div>
      </div>
      <div className={styles.body}>
        <h2 className={styles.header}>{ attribute }</h2>
        <p className={styles.description}>
          { description.preBoldText }
          <span className={styles.bold}>
            { description.boldText }
          </span>
        </p>
      </div>
    </div>
  );
}

RiskRewardResponsibilityCard.propTypes = {
  attribute: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

function getIconByAttribute(attribute) {
  switch (attribute) {
    case 'Risk':
      return riskIcon;
    case 'Reward':
      return rewardIcon;
    case 'Responsibility':
      return responsibilityIcon;
    default:
      return null;
  }
}