import styles from './OverviewCards.module.css';
import archetypeDiscoveryIllustration from '../../assets/icons/archetype-discovery_dark.svg';
import innovationMindsetIllustration from '../../assets/icons/innovation-mindset_dark.svg';
import cohesionQuotientIllustration from '../../assets/icons/team-cohesion-quotient_dark.svg';
import DynamicText from '../DynamicText';

export default function OverviewCards() {
  return (
    <div className={styles.flexContainerCards}>
      <div 
        className={styles.card}
        data-cy='archetype-discovery-card'
      >
        <img
          src={archetypeDiscoveryIllustration}
          alt='Archetype Discovery Illustration'
        />
        <h3 className={styles.archetypeHeader}>Archetype Discovery</h3>
        <DynamicText
          texts={[
            'Every innovator has a Dominant and Subdominant Archetype as an Architect, Creative or Techie.',
            'We believe every innovator has a Dominant and Subdominant Archetype as an Architect, Creative or Techie.'
          ]}
          breakpoints={[ 992 ]}
        />
      </div>
      <div 
        className={styles.card}
        data-cy='innovation-mindset-card'
      >
        <img
          src={innovationMindsetIllustration}
          alt='Innovation Mindset Illustration'
        />
        <h3 className={styles.innovationHeader}>Innovation Mindset</h3>
        <DynamicText
          texts={[
            'You relationship with Risk, Reward and Responsibility, will reveal a strength in Entrepreneurship or Intrapreneurship.',
            'Based on three core factors of Risk, Reward and Responsibility, your mindset will reveal a strength in Entrepreneurship or Intrapreneurship.'
          ]}
          breakpoints={[ 992 ]}
        />
      </div>
      <div 
        className={styles.card}
        data-cy='cohesion-quotient-card'
      >
        <img
          src={cohesionQuotientIllustration}
          alt='Team Cohesion Quotient Illustration'
        />
        <div>
          <h3 className={styles.cohesionHeader}>Team Cohesion Quotient</h3>
          <DynamicText
            texts={[
              'By understanding your Communication, Collaboration and Compromise styles, we can refine team harmony.',
              'By understanding your Communication style, Collaboration preference and Compromise tendencies, we can find and refine team harmony.'
            ]}
            breakpoints={[ 992 ]}
          />
        </div>
      </div>
    </div>
  );
}