import styles from './Dom_SubDomCards.module.css';

export default function Dom_SubDomCards() {
  return (
    <div 
      className={styles.Dom_SubDomCards}
      data-cy='Dom_SubDomCards'
    >
      <div 
        className={styles.card}
        data-cy='dominant-archetype-card'
      >
        <h6 className={styles.header}>Dominant Archetype</h6>
        <p className={`${styles.body} ${styles.bold}`}>Represents your skills honed over time, such as how you show up for work or move in your zone of genius.</p>
        <p className={styles.body}>Your Dominant domain is where you experienced the most improvement and proficiency. </p>
      </div>
      <div 
        className={styles.card}
        data-cy='subdominant-archetype-card'
      >
        <h6 className={styles.header}>Subdominant Archetype</h6>
        <p className={`${styles.body} ${styles.bold}`}>Represents your natural talents, such as your innate ability to flow freely and flex certain inalienable abilities.</p>
        <p className={styles.body}>Your Subdominant domain is a beautiful place of raw talent to begin exploring and building confidence in.</p>
      </div>
    </div>
  );
}