import globalStyles from '../../styles/global.module.css';
import styles from './InfoBlock.module.css';
import UnderstandingTheNarrative from '../../components/UnderstandingTheNarrative';
import Dom_SubDomCards from '../../components/Dom_SubDomCards';
import SkillsTalentsRank from '../../components/SkillsTalentsRank';
import SkillsTalentsRankCards from '../../components/SkillsTalentsRankCards';
import linePattern from '../../assets/line-patterns/line-pattern-1.svg';
import polygon1 from '../../assets/icons/polygon-yellow-1.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function InfoBlock() {
  const width = useWindowWidth();

  return (
    <section
      className={globalStyles['container-width']}
      data-cy='info-block'  
    >
      {
        width < 768 ?
          <MobileLayout />
          :
          <DesktopLayout />
      }
    </section>
  );
}

function MobileLayout() {
  return (<>
    <UnderstandingTheNarrative />
    <Dom_SubDomCards />
    <SkillsTalentsRank />
    <SkillsTalentsRankCards />
  </>);
}

function DesktopLayout() {
  return (<>
    <UnderstandingTheNarrative />
    <SkillsTalentsRank />
    <div className={globalStyles.relative}>
      <hr className={styles.hr} />
      <BackgroundDesignLeft />
      <Dom_SubDomCards />
      <SkillsTalentsRankCards />
      <BackgroundDesignRight />
    </div>
  </>);
}

function BackgroundDesignLeft() {
  const width = useWindowWidth();

  if ( width < 992 ) {
    return null;
  }

  return (<>
    <img
      src={linePattern}
      alt=''
      className={styles.linePatternLeft}
      data-cy='line-pattern-left'
    />
    <img
      src={polygon1}
      alt=''
      className={styles.polygonLeft}
      data-cy='polygon-left'
    />
  </>);
}

function BackgroundDesignRight() {
  const width = useWindowWidth();

  if ( width < 1440 ) {
    return null;
  }

  return (<>
    <img
      src={linePattern}
      alt=''
      className={styles.linePatternRight}
      data-cy='line-pattern-right'
    />
    <img
      src={polygon1}
      alt=''
      className={styles.polygonRight}
      data-cy='polygon-right'
    />
  </>);
}