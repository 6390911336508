import styles from './ACTModelCard.module.css';
import PropTypes from 'prop-types';

export default function ACTModelCard({ triadInfo }) {
  const { triadName, triad } = triadInfo;
  return (
    <div className={styles.ACTModelCard}>
      <h4 className={styles.triadName}>{ triadName }</h4>
      {
        triad.map( member => {
          return (
            <div 
              key={ member.name } 
              className={styles.member}
            >
              <img
                src={ member.image } 
                alt={ member.name }
                className={styles.image}
              />
              <div className={styles.memberInfo}>
                <h5>{ member.name }</h5>
                <p>{ member.role }</p>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

const repeatedPropTypes = PropTypes.shape({
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
}).isRequired;

ACTModelCard.propTypes = {
  triadInfo: PropTypes.shape({
    triadName: PropTypes.string.isRequired,
    triad: PropTypes.arrayOf(repeatedPropTypes).isRequired,
  }).isRequired,
};