import styles from './UnderstandingTheNarrative.module.css';
import illustrationImage from '../../assets/illustrations/understanding-the-narrative.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function UnderstandingTheNarrative() {
  return (
    <section
      className={styles.UnderstandingTheNarrative}
      data-cy='understanding-the-narrative'
    >
      <img
        src={illustrationImage}
        alt='Understanding the Narrative'
        className={styles.illustration}
      />
      <div className={styles.textContent}>
        <h2 className={styles.header}>Understanding the Dominant & Subdominant Narrative</h2>
        <p className={styles.body}>
          <span>You may wonder: </span>
          <span className={styles.bold}>“Why are there Dominant and Subdominant Archetypes?” </span>
          <span>Well, there are multiple angles, layers, and perceptions of who we are, how we move, and how we communicate in the world.</span>
        </p>
      </div>
      <EncouragementTag />
    </section>
  );
}

function EncouragementTag() {
  const width = useWindowWidth();
  if ( width >= 768 ) {
    return null;
  }

  return (
    <div className={styles.EncouragementTag}>
      <p>
        <span>Lean in and </span>
        <span className={styles.bold}>act different.</span>
      </p>
    </div>
  );
}