/**
 * 
 * @param {[]str} texts (should be one index longer than breakpoints[], texts[0] is the text before the first breakpoint)
 * @param {[]num} breakpoints
 * @param {num} windowWidth
 * @returns string
 */
export function getTextByBreakpoint(texts, breakpoints, windowWidth) {
  const textIndex = breakpoints.findIndex(bp => windowWidth >= bp) + 1; // +1 because index 0 is the default text
  return texts[textIndex];
}

/**
 * 
 * @param {obj} viewportComponentMapping (keys are numbers representing viewport widths, values are ordered arrays of components for that viewport)
 * @param {num} windowWidth 
 * @returns array of components
 */
export function sortComponentsByWindowWidth( viewportComponentMapping, windowWidth ) {
  // Find last index where windowWidth is greater than or equal to the key
  let viewportIndex = 0;
  Object.keys( viewportComponentMapping ).forEach( viewport => {
    if ( windowWidth >= viewport ) {
      viewportIndex = viewport;
    }
  });

  // Return correct ordered array of components
  return viewportComponentMapping[viewportIndex];
}