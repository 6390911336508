import kanyeImg from '../assets/images/kanye-west.png';
import virgilImg from '../assets/images/virgil-abloh.png';
import saraImg from '../assets/images/sara-blakely.png';
import adrianaImg from '../assets/images/adriana-cisneros.png';
import oprahImg from '../assets/images/oprah-winfrey.png';
import jkImg from '../assets/images/jk-rowling.png';

const entreIntrapeneurPairs = [
  {
    entrepeneur: {
      name: 'Kanye West',
      image: kanyeImg,
      description: 'Kanye shook the status quo of industry routines by creating his own vision and executing on his own to make sure his voice was heard.'
    },
    intrapreneur: {
      name: 'Virgil Abloh',
      image: virgilImg,
      description: 'By working with key partners like Nike and innovating luxurious brands like Louis Vuitton, Virgil was able to create a fresh yet familiar approach to self-expression.'
    }
  },
  {
    entrepeneur: {
      name: 'Sara Blakely',
      image: saraImg,
      description: 'Through astute problem-solving, creative PR tactics and pure grit, the Founder of Spanx became the youngest self-made female billionaire and still continues to build her empire today.'
    },
    intrapreneur: {
      name: 'Adriana Cisneros',
      image: adrianaImg,
      description: 'Learning from her father’s and grandfather’s practices, Adriana restructured their multigenerational business, Grupo Cisneros, which today is one of the largest privately-held media conglomerates in the world.'
    }
  },
  {
    entrepeneur: {
      name: 'Oprah Winfrey',
      image: oprahImg,
      description: 'From a demoted news anchor to now considered one of the most influential people in the world, Oprah wrote her own playbook to reach unprecedented success in TV.'
    },
    intrapreneur: {
      name: 'J.K. Rowling',
      image: jkImg,
      description: 'The Harry Potter author’s uncanny ability to construct lively worlds with captivating characters helped transform a book series into a cultural phenomenon that shook the literary world.'
    }
  },
];

export default entreIntrapeneurPairs;