import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './customCircleStyles.css';
import styles from './ProgressCircle.module.css';
import infoIcon from '../../../assets/icons/info.svg';

export default function ProgressCircle({ score, year }) {
  const scorePercentage = score / 20 * 100;
  return (
    <CircularProgressbarWithChildren value={ scorePercentage }>
      <div className={styles.ProgressCircleChildren}>
        <Tooltip
          score={score}
          year={year}
        />
      </div>
    </CircularProgressbarWithChildren>
  );
}

ProgressCircle.propTypes = {
  score: PropTypes.number.isRequired,
  year: PropTypes.string.isRequired,
};

function Tooltip({ score, year }) {
  const tooltipText = `In ${year}, ${score} out of 20 teams developed prototypes.`;
  return (<>
    <ReactTooltip
      id={year}
      place='bottom'
      effect="solid"
      globalEventOff='click'
    >
      <p className={styles.tooltipText}>{ tooltipText }</p>
    </ReactTooltip>
    <div
      className={styles.ProgressCircleTooltip}
      data-tip={tooltipText}
      data-for={year}
      data-event='click'
    >
      <h2>{ score }/20</h2>
      <h4>{ year }</h4>
      <img
        src={infoIcon}
        alt='info'
        className={styles.infoIcon}
      />
    </div>
  </>);
}

Tooltip.propTypes = {
  score: PropTypes.number.isRequired,
  year: PropTypes.string.isRequired,
};