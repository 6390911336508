import styles from './InnovationMindsetAbility.module.css';
import icon from './img/innovation-mindset-head-icon.svg';

export default function InnovationMindsetAbility() {
  return(
    <div
      className={styles.abilityContainer}
    >
      <div className={styles.logoContainer}>
        <img
          src={icon}
          className={styles.logo}
        />
      </div>
      <p className={styles.subtitle}>Everybody has the ability to create, but innovating is a muscle that we all must exercise with careful use.</p>
    </div>
  );
}
