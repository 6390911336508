import styles from './Footer.module.css';
import PropTypes from 'prop-types';

import logo from './img/act-house-footer-logo.svg';
import facebook from './img/facebook.svg';
import instagram from './img/instagram.svg';
import twitter from './img/twitter.svg';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <a 
        className={styles.logoContainer}
        href='https://www.act.house/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          src={logo}
          alt='act assessment logo'
        />
      </a>
      <div className={styles.footerLinks}>
        <FooterItems />
        <SocialMedia />
      </div>
    </footer>
  );
}

function FooterItems () {

  const items = [
    { text: 'Who We Are', href: 'https://www.act.house/who-we-are', id: 0, class: '' },
    { text: 'Accelerator', href: 'https://www.act.house/accelerator', id: 1, class: '' },
    { text: 'Assessment', href: 'https://www.act.house/act-assessment', id: 2, class: 'active' },
    { text: 'Cohorts', href: 'https://www.act.house/cohorts', id: 3, class: '' },
    { text: 'Contact Us', href: 'https://www.act.house/contact-us', id: 4, class: '' }
  ];

  return (
    <ul className={styles.footerItems}>
      {items.map(item => {
        return (
          <li
            key={item.id}
            className={item.class === 'active' ? styles.footerItemActive : styles.footerItem }
          >
            <a 
              href={item.href}
              target='_blank'
              rel='noopener noreferrer'
            >
              {item.text}
              <SuperText indicator={item.class} />
            </a>
          </li>
        );
      })}
    </ul>
  );
}
FooterItems.propTypes = {
  indicator: PropTypes.string
};

function SuperText ({indicator}) {
  if(indicator === 'active' ) {
    return (
      <span className={styles.superText}>BETA</span>
    );
  }
  return;
}
SuperText.propTypes = {
  indicator: PropTypes.string
};

function SocialMedia () {

  return (
    <ul className={styles.socialMedia}>
      <li>
        <a
          href='https://www.twitter.com/theacthouse'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={twitter}
            alt='act house twitter'
          />
        </a>
      </li>
      <li>
        <a
          href='https://www.instagram.com/theacthouse/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={instagram}
            alt='act house instagram'
          />
        </a>
      </li>
      <li>
        <a
          href='https://www.facebook.com/TheACTHouse/'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={facebook}
            alt='act house facebook'
          />
        </a>
      </li>
    </ul>
  );
}
