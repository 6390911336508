import PropTypes from 'prop-types';
import { getTextByBreakpoint } from '../../utils';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function DynamicText({ texts, breakpoints, className }) {
  const width = useWindowWidth();
  const text = getTextByBreakpoint(texts, breakpoints, width);
  return (
    <p className={className}>
      { text }
    </p>
  );
}

DynamicText.propTypes =  {
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  breakpoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
};