import PropTypes from 'prop-types';
import errorIllustration from '../../assets/illustrations/error-illustration.svg';
import styles from './Error.module.css';

export default function Error({ message }) {
  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorTextContainer}>
        <h2>Yikes!</h2>
        <ErrorMessage message={message} />
        <p>
          <span>If the problem persists, reach out to </span>
          <a
            href='mailto:beta@act.house'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.errorLink}
          >
            beta@act.house
          </a>
          <span> to see how we can help.</span>
        </p>
      </div>
      <img
        className={styles.errorIllustration}
        src={errorIllustration}
        alt='error'
      />
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.string,
};

function ErrorMessage({ message }) {
  if (!message) {
    return (<>
      <p>Looks like our site is acting out.</p>
      <p>Don’t take it personally though.</p>
    </>);
  }
  
  return (
    <p>{ message }</p>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
};