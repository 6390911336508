import styles from './CCC_Cards.module.css';
import './CCC_Cards.css';
import CCC_Card from '../CCC_Card';
import linePattern from '../../assets/line-patterns/line-pattern-1.svg';
import polygon1 from '../../assets/icons/polygon-green-1.svg';
import useWindowWidth from '../../hooks/useWindowWidth';


export default function CCC_Cards() {
  const windowWidth = useWindowWidth();

  return (
    <section>
      { windowWidth < 768 ?
        <Carousel />
        :
        <div className={styles.cardContainer}>
          <CCC_Card
            cardType='Communication'
          />
          <CCC_Card
            cardType='Collaboration'
          />
          <CCC_Card
            cardType='Compromise'
          />
          <BackgroundPatterns />
        </div>
      }
    </section>
  );
}

// Swiper imports
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

function Carousel() {
  return (
    <div 
      className={styles.CCC_Cards}
      data-cy='ccc-cards-carousel'
      id='ccc-cards-carousel'
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        <SwiperSlide className={styles.SwiperSlide}>
          <CCC_Card
            cardType='Communication'
          />
        </SwiperSlide>
        <SwiperSlide>
          <CCC_Card
            cardType='Collaboration'
          />
        </SwiperSlide>
        <SwiperSlide>
          <CCC_Card
            cardType='Compromise'
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

function BackgroundPatterns() {
  return (<>
    <img
      className={`${styles.linePattern} ${styles.linePattern1}`}
      src={linePattern}
      alt="background line pattern"
    />
    <img
      className={`${styles.linePattern} ${styles.linePattern2}`}
      src={linePattern}
      alt="background line pattern"
    />
    <img
      className={`${styles.polygon} ${styles.polygon1}`}
      src={polygon1}
      alt="background polygon"
    />
    <img
      className={`${styles.polygon} ${styles.polygon2}`}
      src={polygon1}
      alt="background polygon"
    />
  </>);
}