import morpheusImage from '../assets/images/morpheus.png';
import trinityImage from '../assets/images/trinity.png';
import neoImage from '../assets/images/neo.png';
import michaelJordanImage from '../assets/images/michael-jordan.png';
import scottiePippenImage from '../assets/images/scottie-pippen.png';
import dennisRodmanImage from '../assets/images/dennis-rodman.png';
import steveJobsImage from '../assets/images/steve-jobs.png';
import steveWozniakImage from '../assets/images/steve-wozniak.png';
import jonyIveImage from '../assets/images/jony-ive.png';

const act_model_examples = [
  {
    triadName: 'Matrix',
    triad: [
      {
        image: morpheusImage,
        name: 'Morpheus',
        role: 'The Captain'
      },
      {
        image: trinityImage,
        name: 'Trinity',
        role: 'The Force'
      },
      {
        image: neoImage,
        name: 'Neo',
        role: 'The Hero'
      },
    ]
  },
  {
    triadName: 'Chicago Bulls',
    triad: [
      {
        image: michaelJordanImage,
        name: 'Michael Jordan',
        role: 'Shooting Guard'
      },
      {
        image: scottiePippenImage,
        name: 'Scottie Pippen',
        role: 'Small Forward'
      },
      {
        image: dennisRodmanImage,
        name: 'Dennis Rodman',
        role: 'Power Forward'
      },
    ]
  },
  {
    triadName: 'Apple',
    triad: [
      {
        image: steveJobsImage,
        name: 'Steve Jobs',
        role: 'Visionary'
      },
      {
        image: steveWozniakImage,
        name: 'Steve Wozniak',
        role: 'Developer'
      },
      {
        image: jonyIveImage,
        name: 'Jony Ive',
        role: 'Designer'
      },
    ]
  },
];

export default act_model_examples;